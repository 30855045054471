import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updatePost, renderPost } from '../api'; // Assuming you're using axios for HTTP requests
import "../css/Michels.css";
import { useNavigate, useParams } from 'react-router-dom';
import { getPagesApi } from '../api';

const EditPost = () => {
  const { short_title } = useParams();
  const quillRef = useRef(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  let token = localStorage.getItem('authToken');
  const [pages, setPages] = useState([]);
  const [postPages, setPostPages] = useState([]);
  const [payWalled, setPayWalled] = useState(false);

  useEffect(() => {
    const getPages = async () => {
        try {
            const response = await getPagesApi();
            console.log(response.data);
            setPages(response.data);
          } catch (error) {
            console.error('Error:', error);
          }

    };
    getPages();
  }, []);

  const handleCheckboxChange = (pageName) => {
    if (postPages.includes(pageName.toLowerCase())) {
      setPostPages(postPages.filter(name => name !== pageName.toLowerCase()));
    } else {
      setPostPages([...postPages, pageName.toLowerCase()]);
    }
  };


  useEffect(() => {
    if (short_title) {
      renderPost(short_title)
        .then(response => {
          console.log(response);
          setTitle(response.data.title);
          setDescription(response.data.description);
          setContent(response.data.content);
          setPostPages(response.data.postPages);
          setPayWalled(response.data.payWalled);
        });
    }
  }, [short_title]);

  const handleSubmit = async () => {
    try {
      const payload = { token, title, description, content, short_title, postPages, payWalled };
      let response;
      if (short_title) {
        response = await updatePost(payload);
      } 
      console.log(response.data);
      navigate('/');
    } catch (error) {
      console.error('Error submitting post:', error);
      alert("An error occurred: " + error.message);
      window.location.reload();
    }
  };

  const handlePaste = async (e) => {
    const clipboard = (e.clipboardData || window.clipboardData);
    const htmlContent = clipboard.getData("text/html");
    const wrapper = document.createElement('div');
    wrapper.innerHTML = htmlContent;
    const imgElements = Array.from(wrapper.querySelectorAll('img'));

    if (imgElements.length > 0) {
        e.preventDefault();  // Prevent Quill's default paste behavior.
        for (const imgElement of imgElements) {
            if (imgElement.src) {
                fetch(imgElement.src)
                    .then(response => response.blob())
                    .then(insertImageBlob)
                    .catch(error => {
                        console.error('Error fetching the image:', error);
                    });
            }
        }
    }
};

  const insertImageBlob = (blob) => {
      const reader = new FileReader();
      reader.onload = (event) => {
          const base64 = event.target.result;
          const quillInstance = quillRef.current.getEditor();
          const range = quillInstance.getSelection(true);
          quillInstance.insertEmbed(range.index, 'image', base64);
      };
      reader.readAsDataURL(blob);
  };

  return (
    <div className='Michels'>
      <div className='Michels-text'>
        <input 
          type="text" 
          placeholder="Title" 
          value={title} 
          className="title-input"
          onChange={e => setTitle(e.target.value)}
        />
        <textarea 
          placeholder="Description" 
          value={description} 
          className="description-input"
          onChange={e => setDescription(e.target.value)}
        />
        <ReactQuill 
          value={content} 
          onChange={setContent}
          ref={quillRef}
          onPast={handlePaste} 
        />
        {pages.filter(page => page.type === 'dynamic').map(page => (
          <div key={page.id}>
            <input 
              type="checkbox" 
              id={page.name} 
              name={page.name} 
              checked={postPages.includes(page.name.toLowerCase())}
              onChange={() => handleCheckboxChange(page.name)}
            />
            <label htmlFor={page.name}>{page.name}</label>
          </div>
        ))}
        <button onClick={handleSubmit}>Submit</button>
        <div>
          <input 
                type="checkbox" 
                id="paywall" 
                name="Paywall"
                checked={payWalled}
                onChange={() => setPayWalled(!payWalled)}
              />
            <label htmlFor="paywall">Paywall</label>
        </div>
      </div>
    </div>
  );
};

export default EditPost;
