import "../../css/Michels.css"
import michels from "../../pdfs/OnPower.pdf";
var Latex = require("react-latex")

export default function Mosca ()
{
    return (
        <div className='Michels'>
            <div className='Michels-text'>
            <h1 id='essayTitle'>Review of <i>On Power</i></h1>
            <p><i></i></p>
            <h2>Introduction</h2>
            <p>Bertrand de Jouvenel's <i>On Power</i> is the grand culmination of early 20th century elite theory. Within it is contained an intelligent, original synthesis of the ideas of Mosca, Michels, and Pareto alongside important, original work. Published in 1948, the book begins with a section entitled "The Minotaur Presented," which sets the stage for the rest of the work. In this introduction, Jouvenel asks for an explanation for the massive expansion of the purview of war: (pg. 1)</p>
            <q id="quote1"><i>The war through which we have lived has surpassed in savagery and destructive force any yet seen by the Western World. This force has been generated by the unparalleled scale on which men and materials have been thrown in. Not only have armies been raised to the number of ten, of fifteen, of twenty millions of men, but also, behind the lines, whole populations have been conscribed that these armies might not lack the latest and deadliest weapons. Every inhabitant of a country with breath in him has served war's turn, an the non-essential tasks which sweeten life have come to be tolerated at all only so far as they have been thought necessary to sustain the spirit of the one vast instrument of war into which whole peoples have been forged. In this war everyone—workmen, peasants, and women alike—is in the fight, and in consequence everything, the factory, the harvest, even the dwelling-house, has turned target. As a result the enemy to be fought has been all flesh that is and all soil, and the bombing plane has striven to consummate the utter destruction of them all. The war would have counted fewer participants, it would have wrought a less frightful havoc, had not certain passions, fiercely and unanimously felt, so transformed men's natures that a total distortion of their normal modes of doing became possible. The task of stirring and sustaining these passions has been that of a munition of war without which the others must have proved ineffectual—propaganda. Savagery in act is sustained by savagery of feelings; this has been the work of propaganda. The most surprising feature of the spectacle which we now present to ourselves is that we feel so little surprise at it.</i></q>
            <br></br>
            <q id="quote1"><i>That the entire populations of Great Britain and the United States, countries where there was no military conscription and the rights of the individual were held sacred, should have become merely so much "human potential," distributed and applied by Power as might best maximize the war effort, 2 is easily explained. Germany was employing in her design of world conquest all her national resources,and there was no restraining her by other countries with only a part of theirs.</i></q>
            <br></br>
            <q id="quote1"><i>There is no disputing this explanation, but it does not explain enough. Hitler was not the first of Europe's would-be conquerors. How comes it that neither Napoleon, nor Frederick II, nor Charles XII, ever achieved the total mobilization of his entire people for war?</i></q>
            <p>His answer is core to the book:</p>
            <q id="quote1"><i>Simply because they were unable to. And there have been other occasions in history when, with some formidable aggressor to repel, rulers would dearly have liked to dip deeply into the national resources; it will be enough to instance the emperors of the sixteenth century, who, even when the Turk was ravaging their lands, were never able, for all their wide domains, to raise armies which were more than moderate in size.</i></q>
            <p>They were unable to, according to Jouvenel, merely because they lacked to technology and political formula to do so, not because they didn't want to. Echoing Michels, the clearest exponent of Orwellian motivation theory among the Italian Elitists, Jouvenel posits that rulers of the State have always wanted to expand the State, and therefore their own power, as much as possible.</p>
            <q id="quote1"><i>It is, alas, no longer possible for us to believe that by smashing Hitler and his regime we are striking at the root of the evil. Even while we do it, we are already making plans for after the war, which will make the state the arbiter of every individual destiny and will place, inevitably, in Power's hands means adequate to the vastness of its task. (pg. 12).In the order of nature everything dies which is not sustained by an intense and brutal love of self. Power, in the same way, can only maintain the ascendancy necessary to it by the intense and brutal love which the rulers have for their authority. It has, alas! to be agreed that tenderness of heart, going to the length of self-denial, spells self-inflicted death to Power. Instances of this are the case of Lamartine and the ever memorable one of Louis XVI. In an illuminating passage Tocqueville has shown us the monarchy turning into its own prosecutor for its crimes, and calling down on itself a wrath from which it has no wish to protect itself. It lacked the will to live: "Go and tell the Swiss not to fire." (pg. 120).To be completely acquitted of egoism by the generality, rulers need only affect a studied austerity and a strict economy. As if the real pleasures of authority were not quite other!</i></q>
            <br></br>
            <q id="quote1"><i>In every condition of life and social position a man feels himself more of a man when he is imposing himself and making others the instruments of his will, the means to the great ends of which he has an intoxicating vision. To rule a people, what an extension of the ego is there! The ephemeral delight given us when, after a long illness, our limbs return to their duty can alone give us some small idea of that incomparable pleasure of radiating daily impulsions into an immense mass and prompting the distant movements of millions of unknown limbs. It can be savoured in the shadows of a cabinet by a grey-haired and black-coated official. The thoughts he thinks keep pace with the orders he gives. He sees in his mind's eye the canal being dug along the line which his pencil has traced on the map, the boats which will shortly give it life, the villages springing up on its banks, the profusion of merchandise heaped high on the quays of his dream-town. It is not surprising that Colbert, on coming to his desk in the morning, rubbed his hands for joy, as the tale is told by Perrault.</i></q>
            <br></br>
            <q id="quote1"><i>This intoxicating pleasure of moving the pieces on the board of the social game breaks out continually in Napoleon's correspondence. Is it merely attention to detail that makes him, even in time of peace, prescribe the route that each troop of soldiers is to take across his vast empire, determine the number of muskets to be stored in each armoury, how many cannon balls there shall be in each place, or how much cotton shall be imported into France and through what customs houses—the way which it shall follow and the time which it shall take to come from Salonika? Far from it: when he regulates the vast traffic of men and goods, he feels, as it were, the coursing of an infusion of new blood which supplements his own.</i></q>
            <br></br>
            <q id="quote1"><i>Is not the will to Power rooted deep in human nature, and have not the outstanding qualities of leadership needed for the handling of a machine which goes ever from strength to strength often had for companion the lust of conquest? (pg. 12).</i></q>
            <p>The more labor they control, the fewer rivals in their way, the happier the leader. Thus the State, being the center of coercion, is ever-expanding, always giving new rights to itself, under the direction of those who currently reside in it, because new rights to the State mean that more enemies can be attacked in increasingly diverse ways, and more of their natural-slave biomass can be scooped up by aligned parties in the process.</p>
            <p>The preceding language is somewhat imprecise. In Jouvenel's model, taking what may be called "natural slave biomass", or just biomass for short, is analytically equal to attacking rivals: (pg. 177)</p>
            <q id="quote1"><i>Whoever does not wish to render history incomprehensible by departmentalizing it—political, economic, social—would perhaps take the view that it is in essence a battle of dominant wills, fighting in every way they can for the material which is common to everything they construct: the human labour force.</i></q>
            <br></br>
            <p>How does an elite actor coercively seize his rival's biomass? According to Jouvenel, there are two conditions that must be met: first, the technological means to discipline, coerce, and seize must be in place. Napoleon never could have conquered feudal knight-nobles with an army of peasants armed with pitch-forks. Certain forms of control, contrary to too large a number of elite actors, could hardly be established, much less maintained, without advances in the means of propaganda. The current Western brainwashing regime's success in convincing the public of blank-slatism, an obvious and scientifically-proven falsehood, is exemplar here, as it is very likely to be instrumental in allowing for the State's enforcement of laws which ban corporations of racist white men. Second, the political formula must be degraded such that bystanders fail to act in support of the victim, and, if possible, such that the victim fails to even attempt to defend himself. Stalin could have never ruled if it were not accepted by the Party elite that his behavior was licit. If a Christian political formula situated a Law above Stalin, he would have never survived any transgression of said law. He never would have established the power to seriously violate it in the first place, even if the technological means were there.</p>
            <p>Consequently, the history of power is one part the degradation of the political formula, away from its initial position situated in the realm of Natural Law, toward carte blanche theories of the divine right of Caesars or Parliaments, and another part the accumulation of the technological means to exploit the degradation of the Law. Quoting Jouvenel: (pg. 25):</p>
            <q id="quote1"><i>It thus appears that obedience is largely compounded of creed, credence, and credit. Force alone can establish Power, habit alone can keep it in being, but to expand it must have credit—a thing which, even in its earlier life, it finds useful and has generally received in practice. As a description of Power, rather than as a definition, we may now call it a standing corporation, which is obeyed from habit, has the means of physical compulsion, and is kept in being partly by the view taken of its strength, partly by the faith that it rules as of right ( in other words, its legitimacy), and partly by the hope of its beneficence.</i></q>
            <p>Here, "force" maps onto technology and "credit" maps onto the degradation of the political formula. Technology increase the efficiency of force, which is necessary for expansion, but "credit", meaning change in political formula, is a necessary prerequisite to force, according to Jouvenel.</p>
            <p>The role of technology in history is straightforward. The role of "memetics" is more contested. Jouvenel expands on his view:</p>
            <q id="quote1"><i>The role played by credit in the advancement of Power's strength needed underlining, so as to explain why the theories which stir the imagination concerning it are so valuable to it. Whether they induce greater respect for an abstract sovereignty, or whether they arouse more devotion to an abstract Common Good, they greatly aid Power and open up to it new pastures.</i></q>
            <br></br>
            <q id="quote1"><i>Obedience, it is said, becomes a duty because of the undeniable existence "of an ultimate right of command in Society," called sovereignty, a right which extends "to controlling the actions of Society's members with, in the background, power to coerce them, a right to which all private individuals have to submit without possibility of resistance.</i></q>
            <br></br>
            <q id="quote1"><i>Power makes use of this right even though in the general view it does not belong to it. It is denied that this absolute and unbounded right, transcending all private rights, can possibly belong to one man or to one group of men. Only to the most august incumbent, to God, or to society as a whole, will we commit, with no thought of bargaining reserve, the entire conduct of our lives. (pg. 26)</i></q>
            <p>Jouvenel restates Mosca's doctrine of political formula -- some abstraction rules in the minds of the people and even elite rivals. The individuals who control the State are merely servants of this great power, which is called sovereignty. Historically, sovereignty is either divine or of "the people": "this Will is either the 'Divine Will' or the 'general will"' (pg. 27).</p>
            <p>Next, Jouvenel traces the evolution of theories of sovereignty, beginning by asking the following: (pg. 27)</p>
            <q id="quote1"><i>Our present concern is with the psychological influence of these doc- trines, and the way in which they have affected human beliefs in regard to Power and, through them, man's attitude of mind towards it; that in its turn has determined Power's extent. Have they acted on Power as a discipline, by forcing it to own allegiance to a beneficent being? Or have they canalized its stream, by creating checks which can bind it to keep faith? Or have they limited it, by restricting the share of sovereign right allowed it?</i></q>
            <br></br>
            <q id="quote1"><i>Many writers on theories of sovereignty have worked out one or the other of these restrictive devices. But in the end every single such theory has, sooner or later, lost its original purpose, and come to act merely as a springboard to Power, by providing it with the powerful aid of an invisible sovereign with whom it could in time successfully identify itself. The theory of a divine sovereignty led to absolute monarchy; the theory of a popular sovereignty led at first to parliamentary supremacy, and finally to plebiscitary absolutism.</i></q>
            <p>Jouvenel buttresses this claim by showing how Locke, Rousseau, Hobbes, and others wanted to restrict Power's expansion. Nevertheless, their theories of popular sovereignty eventually culminated in Napoleon, Stalin, and Hitler. Even the parliaments which exist in between these Caesars are incredibly expansionary compared to past States, according to Jouvenel.</p>
            <p>This is because as sovereignty moved away from God and towards Kings and the People, law became less natural and more malleable. Power meddled in theories of sovereignty so it could be Law to its whim.</p>
            <q id="quote1"><i>A Power which regulates human behaviour according to its own notions of social utility is absolute in a quite different way from one whose subjects have had their actions prescribed for them by God. And here we glimpse the fact that the denial of a divine lawgiving and the establishment of a human lawgiving are the most prodigious strides which society can take towards a truly absolute Power. So long as a supernatural origin was ascribed to law, this step remained untaken. (pg. 200).</i></q>
            <br></br>
            <q id="quote1"><i>All the great civilizations were formed in the framework of a divine law given to society, a law which even the strongest will of all, that of the wielders of Power, was powerless to shatter or replace.</i></q>
            <br></br>
            <q id="quote1"><i>So it was with the least religious peoples in history, the Greeks and the Romans. No doubt the principles of Roman law quickly lost any tincture of religious connotation. But their civil ordinances and institutions are, as Ihering has shown, the exact reproduction of ancient ordinances and institutions which had a sacred character. The modern man, imbued as he is with the idea that laws are but man-made rules issued with a view to the convenience of society, will observe with some astonishment that, even at a late stage of civilization, Cicero began his treatise on the laws with a detailed dissertation on the ways of honouring the gods. Yet nothing could be more logical: respect for the laws is but one aspect of respect for the gods.</i></q>
            <br></br>
            <q id="quote1"><i>Cicero expounds the nature of law as clearly as anyone could wish: ``Our greatest philosophers have unanimously concluded that law is neither an invention of man nor anything at all resembling the rules of day-to-day life; rather it is something eternal which governs the universe by the wisdom in which its orders and prohibitions are conceived. According to these same authorities this primitive law is nothing other than the supreme expression of the spirit of God Himself, whose sovereign reason is the source of every precept, whether to do or not to do. From this law comes the nobility of that which the gods have given to mankind, which is in fact the reason and wisdom embodied in the man who has learnt to command what is good and prohibit what is bad.''</i></q>
            <p>Law began as immutable instinct (Jouvenel heavily cites Frazer and the dictatorship of democratic-ancestral custom), then it was enshrined in divine commands, and only after significant degradation of the political formula by the agents of Power did Law become political, malleable and up for debate. Even in the Middle Ages Law was mostly intact: (pg. 27)</p>
            <q id="quote1"><i>The idea that Power is of God buttressed, so it is said, a monarchy that was both arbitrary and unlimited right through the Dark Ages. This grossly inaccurate conception of the Middle Ages is deeply embedded in the unlettered, whom it serves as a convenient starting- point from which to unroll the history of a political evolution to the winning-post, which is liberty.</i></q>
            <br></br>
            <q id="quote1"><i>There is not a word of truth in all this. Let us remember, without at the moment stressing it, that Power in medieval times was shared (with the Curia Regis), limited (by other authorities which were, in their own sphere, autonomous), and that, above all, it was not sovereign. The distinguishing characteristics of a Power which is sovereign are: its possession of a legislative authority; its capacity to alter as it pleases its subjects' rules of behaviour, while recasting at its own convenience the rules which determine its own; and, while it legislates for others, to be itself above the laws, legibus solutus, absolute. Now Power in medieval times was very different: it was tied down, not only in theory but in practice, by the Lex Terrae (the customs of the country), which was thought of as a thing immutable. And when the English Barons uttered their Nolumus leges Angliae mutari they were only giving vent to the general feeling of the time.</i></q>
            <p>Under what circumstances, exactly, has political formula been so severely degraded? Jouvenel recognizes Pareto's cycle of Class I and Class II residues in the elite. In this cycle there are two phases corresponding to the influx or depletion of Class I residues into the elite. Class I residues are "combinatorial", meaning they relate to the support of new ideas. Pareto hypothesizes that people high in Class I residues are high IQ, high openness, low religiosity, low in patriotism, and tend to desire social change. Class II residues include traits regarding "group-persistence." Some of these traits are the negations of Class I traits, and Pareto hypothesizes that people high in Class II residues are low in Class I residues. Such people tend to be religious, dogmatic, patriotic, nationalistic, and bad dynamic decision making. Their ideas are old and sclerotic. During Class I influx, the elite is open to parvenus who tend to be high in Class I residues, because the openness of the elite means Class I merit is selected for when building wealth. Eventually, the concentration of Class I residues reaches a peak; the elite is too deficient in group-persistences, fails to coordinate, and takes change too far. The elite is also far too broad. Generally, a Caesar exploits this situation to establish personal rule; the elite is closed off and slowly Class I residues decline due to regression to the mean and lack of selection for them. Eventually they reach levels which are far too low; the elite becomes unable to act intelligently or to adapt to new situations. When crisis strikes, the time is ripe for high Class I residue outsiders to overthrow the governing elite and to throw open the doors to Power. The cycle then repeats.</p>
            <p>Jouvenel adds to this. The tendency during Class I influx is toward oligarchism. Under such conditions, the level of inter-elite competition is high. Competitors need to build "credit" so that they can use the State in new ways to attack their rivals. When inter-elite conflict reaches a boiling point, the time is ripe for a Caesar to come in and consolidate all of the gains Power has made under his sole authority. Centralized methods consistent with the credit gained are developed and the citizen sees Power meddle in his affairs with an extent and efficiency never before seen. Eventually the Caesar falls and is replaced either by sclerotic oligarchy or a Class I influx regime.</p>
            <p>Jouvenel gives the example of Ancient Rome to illustrate this: (pg. 326)</p>
            <q id="quote1"><i>In the time when the plebeian had no rights, he had obtained, by means of the celebrated secession of the plebeians to the Aventine, the institution of inviolable tribunes, armed with complete powers for protecting him and with the right to halt for his behoof any activity of the government. This tribunician power had about it an arbitrary character which was necessary at first to make up for the plebeian's lack of rights: it should, logically, have disappeared as soon as equality of rights had been realized. Far from that, however, it continued in existence, backed by the Senate, which made clever use of it to check the designs of magistrates who were too independent, and to concentrate finally in its own hands all public authority.</i></q>
            <br></br>
            <q id="quote1"><i>The Senate permitted the tribunes to unite the plebs as a separate community within the city, and to arrange for it to pass by vote resolutions of its own, plebi-scita, resolutions which acquired in the end the status of laws in the true sense.</i></q>
            <br></br>
            <q id="quote1"><i>These laws were very different both in intent and content from those which had in former days been presented by the magistrates, the Senate consenting; the latter had been limited to the formulation of general principles. The tribunician plebiscites, products for the most part of the needs or passions of the passing hour, came often into conflict with the most fundamental principles of the law.</i></q>
            <br></br>
            <q id="quote1"><i>In this way there was introduced into Roman society the essentially erroneous notion that it is the business of legislative authority to prescribe or forbid anything whatever. Anyone who put forward a proposition of a nature seemingly advantageous for the immediate future was blindly applauded, even though his proposition subverted the entire permanent edifice of order. It was the tribunate which habituated the people to the idea of a saviour redressing at a stroke the social balance. Marius and Caesar were to be its heirs, and the emperors would find it an easy task to establish themselves on the ruins of the Republic and liberty.</i></q>
            <p>Here the plebs are entering the relatively open governing elite through wealth accumulation. Formally, this looks like the accumulation of "rights." As these parvenus compete with the Patricians and themselves, they gain ever more "rights" and further degrade the political formula.</p>
            <p>Later on, Jouvenel discusses the Gracchi brothers as an example of this process in action. They attempted to redistribute the ager publicus, the public land hitherto controlled principally by aristocrats, to the urban poor via demagoguery. This example is most revealing because it clearly displays the underlying mechanism that all inter-elite conflict uses, given the principle that said conflict is really competition for control over the biomass. This mechanism is known as High-Low vs. Middle, or HLvM. High is the attacker, who currently possesses the State; the mechanism by which State power operates is the Low. When the Middle disobeys, a bunch of retards are sent to murder them. The Middle is the elite actor who is attacked by the High via the Low. In the case of the Gracchi brothers, they attempted to be the High, to use the mass to attack the Patricians, who were supposed to be the middle, taking their land and dispursing it to non-elites, establishing dominance over their rivals and loyalty from the bribed biomass in the process.</p>
            <p>There are examples of this everywhere. In my book <i>An Empirical Introduction to Youth,</i> I found empirically, before become acquainted with any of the literature mentioned here, that the lie that the brain develops until the age of 25 is primarily weak credit for the expansion of educational system. It is funded by foundations controlled by elite professors who explicitly desire to achieve the goal of "universal college education." In other words, they want nothing less than to seize biomass away from rivals, using the State. The lie about brain development provides credit for the State to grievously deprive people under 21 or 25 of liberties which could allow them to not attend college. This faction of the elite supports <a href="https://archive.ph/RFVi2">raising the age of majority to 21</a> ... or so it would seem. From the article in the link:</p>
            <q id="quote1"><i>The 26th Amendment is as secure as the Second Amendment — that is to say, it isn’t going anywhere. But the acute dilemma posed by the United States’ violent young men and guns could be an opportunity to begin pushing the age of majority back upward for certain purposes. That ought to make gun regulation for Americans under 21 more politically salable.</i></q>
            <br></br>
            <q id="quote1"><i>There could be other benefits. If young peoples’ parents or guardians had to co-sign their student loans, perhaps the volume of student debt, and wasted degrees, would be lower. If child-support payments had to continue beyond age 18, perhaps divorce would not have accelerated as quickly in the late 20th century. There are strong arguments for raising the legal age to 21 from 18 for appearing in pornography. In criminal law, the age of eligibility for the death penalty could also be increased and “youthful offender” programs expanded.</i></q>
            <p>It seems the writer is speaking more of the age of legal maturity and less the age at which one can vote. Laurence Steinberg commented that we need not take away the right to vote; in fact, he supports <i>lowering</i> the age of voting <a href="https://www.nytimes.com/2018/03/02/opinion/sunday/voting-age-school-shootings.html">to 16,</a> because 16 year olds tend to be more liberal.</p>
            <q id="quote1"><i>The young people who have come forward to call for gun control in the wake of the mass shooting at their high school in Parkland, Fla., are challenging the tiresome stereotype of American kids as indolent narcissists whose brains have been addled by smartphones. They offer an inspiring example of thoughtful, eloquent protest.</i></q>
            <br></br>
            <q id="quote1"><i>Unfortunately, when it comes to electing lawmakers whose decisions about gun control and other issues affect their lives, these high schoolers lack any real power. This needs to change: The federal voting age in the United States should be lowered from 18 to 16.</i></q>
            <br></br>
            <q id="quote1"><i>If the voting age were lowered, would that necessitate changing other laws to bring them into alignment? Of course not. We use a wide variety of chronological ages to draw lines between minors and adults when it comes to smoking, driving, viewing violent or sexually explicit movies, being eligible for the death penalty and drinking alcohol. Although the specific ages used for these purposes often lack a good rationale, there is no reason lowering the voting age would require lowering, say, the drinking age, any more than allowing people to drive at 16 should permit them to drink or smoke at that age as well.</i></q>
            <br></br>
            <q id="quote1"><i>In addition to the scientific case for lowering the voting age, there is also a civic argument. Consider the dozen or so countries like Argentina, Austria, Brazil and Nicaragua that allow people to vote at 16 in national, state or local elections. In such countries, voter turnout among 16- and 17-year-olds is significantly higher than it is among older young adults.</i></q>
            <p>In fact, Steinberg actually supports raising the age of <a href="https://www.macfound.org/press/40-years-40-stories/research-network-adolescent-development-and-juvenile-justice">criminal responsibility to 21</a>, saying</p>
            <q id="quote1"><i>Today, the most important issue in justice system reform concerns the extension of the Supreme Court’s decisions in Roper and Miller to individuals who are older than 18. The Network’s study of decision-making found that many of the attributes of juveniles that mitigate their culpability are also characteristic of individuals between 18 and 21. These findings, buttressed by brain science showing continued brain maturation into the early 20s, have prompted courts and lawmakers to ask if people in this age group should have the same protections as juveniles under the age of 18. In 2017, a Kentucky state court, drawing on the Network’s work, ruled that exposing people under 21 to capital punishment violated the state’s constitution. In 2018, a federal district court drew on the Network’s work in ruling that the Miller decision applied to 19-year-olds. Currently, courts across the nation are hearing cases involving late adolescent and young adult offenders, in which defense attorneys are arguing that people between 18 and 21 should also be exempt from capital punishment and mandatory life without parole.</i></q>
            <p>Expansionary derivations need no underlying logic! Why would you not support your biomass have full voting rights?</p>
            <h2>Power Intertia</h2>
            <p>While we have already examined his general model, <i>On Power</i> unfolds like a root; there are multiple substantial branches which are worth examining.</p>
            <p>Book 1 begins with the only math in the text. It is found in the footnotes which were written by the translator, and Jouvenel only hints at it, but it is a good formulation nonetheless.</p>
            <Latex>{`$$\\frac{R_P}{R}$$`}</Latex>
            <p>Jouvenel notices that this fraction monotonically increases throughout history, where the numerator is the amount of resources the State has, and the denominator is the total amount of resources in society. "The study of this relationship is the main purpose of this book" (pg. 18). As we have already seen, the general theory is that the State expands because of Orwellian motivation and inter-elite conflict.</p>
            <p>He goes on to make notice of the role of inertia in power. The obedience of the masses seems to be by habit, once established. This rhymes with Pareto's doctrine of group-persistences, a.k.a Class II residues, which the masses are supposedly high in. At multiple points in <i>The Mind and Society,</i> Pareto emphasizes how custom rules through group-persistences, and how it is an uphill battle to install new custom, but once installed any new custom suddenly becomes as ancient as those preceding it.</p>
            <p>This is interesting because it supposes that power is like a game of King of the Hill; the biomass are like guardians of the King, or rocks which can be pelted at potential challenges. It's difficult to get to the top of the hill, but once there a King can rest on his laurels relative to what it took to climb the rather steep mountain.</p>
            <p>This notion makes sense of a number of phenomena, namely those to do with the degradation of the elite. Why is their rule maintained when there are superior people outside of the elite? Because the inferior governing elite is on the hill of habit, and the superior non-governing natural elite has a much harder task before him in invading than do the hill-squatters in defending.</p>
            <h2>The Relation between Ideas and Power</h2>
            <p>Next Jouvenel discusses how Power seems to always twist ideas to its own ends. As detailed above, while multiple authors on "soveriengty" set out to limit the expansion of Power, in each case their words were distorted into reasons why it should grow. The two overarching types of sovereignty are divine and popular, and in both cases absolutism triumphs eventually (pg. 27):</p>
            <q id="quote1"><i>Many writers on theories of sovereignty have worked out one or the other of these restrictive devices. But in the end every single such theory has, sooner or later, lost its original purpose, and come to act merely as a springboard to Power, by providing it with the powerful aid of an invisible sovereign with whom it could in time successfully identify itself. The theory of a divine sovereignty led to absolute monarchy; the theory of a popular sovereignty led at first to parliamentary supremacy, and finally to plebiscitary absolutism.</i></q>
            <p>In general, theory is the bitch of Power. It inches forward as Power expands. First Power expands, then the new power is used to push theory further, then more expension, then more pushing, then more expansion... (pg. 29):</p>
            <q id="quote1"><i>The same idea, that Power is of God, has, in the course of more than fifteen centuries, been used by its prophets for a great variety of purposes. St. Paul, it is clear, was anxious to combat in the Christian community at Rome its tendencies to civil disobedience, which would, he feared, not only precipitate persecutions but also divert the community's activities from their true purpose, which was the winning of souls. Gregory the Great, writing at a time when the West was a military anarchy, the East a prey to political instability, and the Roman way of life in imminent danger of destruction, felt under the necessity of shoring up Power. The canonists of the ninth century strove to prop up the toppling imperial authority after the Church had, in the general interest, re-established it. As many periods and as many requirements, so many meanings. But it is not the case that the doctrine of Divine Law was dominant at any time before the Middle Ages: it was ideas derived from Roman law which formed the intellectual climate of those days. And if we take up the theory of divine sovereignty in the time of its blossoming, that is to say from the eleventh to the fourteenth centuries, what do we find? That people are repeating St. Paul's formula, "all Power is of God," but less with a view to inducing subjects to obey Power than to inducing Power to obey—God. So far from the Church wishing to confer on princes a divine supremacy by calling them the representatives or the ministers of God, her concern was the very opposite: to make them conscious that, since they held their authority only as a trust, it was their duty to make use of it in accordance with the intention and will of the Master from whom they had received it. It was not a question of her authorizing the prince to make whatever law he pleased, but rather of bending Power's will to a divine law which was its overriding master.</i></q>
            <p>Jouvenel's treatment here is limited. My essay <i>Three Models of Memetics</i> argues that the only ideological theater is that of apparently true ideas. Nonsense, like theories of sovereignty, are only ever music to the ears of the powerful. Consequently, theories of sovereignty will be a total function of Power, which is really, in concurrence with Burnham, control over the means of production, which is in turn based on certain traits, like intelligence, deceptiveness, ambition, and conscientiousness. The only form of credit is what I call brainwashing, convincing victims of false apparently true ideas. This is expensive and is a function of power. Brainwahsing is simply one mechanism of power. Others include threatening to withhold goods and services, physical force, charisma, or genuinely appealing to self-interest in general.</p>
            <h2>The History of Power</h2>
            <p>Jouvenel heavily cites Frazer and claims that at the beginning of civilization, there was a primitive democracy, and ancestral custom or genetic-law enjoyed sovereignty. Kings were sacrificial executives kept in check by the tribe, which was composed of relatively high agency men.</p>
            <p>Then slavery was invented. Now those who had slaves could use them to enforce their will upon the rest of the allegedly free tribe. Absolutist kingship was now possible and began to occur in a cycle with slave owner oligarchism.</p>
            <p>Jouvenel notes that freedom is slavery -- that is, that the Ancients at this point in time understood their freedom was bought by slaves. Only by being freed from labor could they afford to rule.</p>
            <p>This is heavily influenced by Mosca, who argued something similar. One wonders the degree to which slaves were/are a <i>race</i> of domesticated farm animal, bred for docility and obedience by superior elite warrior tribes. Is it possible for a member of this race to ever rule? What is the history of their mixture with freemen?</p>
            <p>Jouvenel also traces the degradation of the political formula. In the beginning there was the dictatorship of a primitive natural law. This declined roughly monotonically according to Jouvenel; even in Ancient Rome law was mostly of the gods, as the Cicero quote shows. In the early middle ages, law was of the Church and therefore roughly of God. Only recently has law been of the divine King and then of the representative of the People.</p>
            <p>It is interesting to note that Costin Alamariu a.k.a Bronze Age Pervert links ancient Greek tyrannies and the rise of philosophy in his 2015 thesis. I have not yet read it in full but it is possible that the reason why the philosophers were patronized was to break down belief in the gods so that absolutism could expand.</p>
            <h2>The Society of Elites</h2>
            <p>Jouvenel examines the formation of nations and finds conquest instead of rational marriage. He concludes, "the state is in essence the result of the successes achieved by a band of brigands who superimpose themselves on small, distinct societies; this band, which is itself organized in a society as fraternal and as full of thieves' justice as you please, behaves towards the vanquished and the subjected as Power in the pure state." (pg. 100). Oligarchy, and the State is therefore the domination of a small society (oligarchy) over a bigger one. This is very reminiscent of Michels.</p>
            <p>What are the implications? I believe that the governing elite may be some necessary size, perhaps the Dunbar number. It needs to be small enough to coordinate as to out-compete others, but if it is too small it will itself be eaten.  An oligarchy will rule because one person is too few, too vulnerable to some others who work together; yet oligarchies have a size limit; not only are most people not fit to coordinate and rule, there may be too many potential rulers, or natural elites. The M must be excluded and oppressed or else those on the inside may find themselves on the outs. Exactly what size the optimal oligarchy is and why must be further investigated.</p>
            <p>This concept of the smaller society within society fits pretty well to the American experience and has inspired in me what might be called the theory of the Patriciate. Jouvenel discusses how the Roman Patriciate also functioned as a society within a society, careful not to over-expand, lest the members' collective culture and individual grips on power intertia be threatened.</p>
            <p>It is possible that any society is ruled by a small class which controls its resources. To become powerful, one generally must be let in; in other words, he must be allowed to share in their resources. Ideally, those who are let in are more optimal inside in then out; they will contribute to the group with their elite traits and help it maintain its collective influence, and they will give back as they have received in kind. Even if one has accumulated his own resources, generally if they are excluded, they will be locked out of most new resources. Only if the group excludes too many with their own resources will they suddenly be threated by a counter-Patriciate.</p>
            <p>My main evidence for this idea at this point is the book <i>Who Rules America?</i> by G. William Domhoff alongside Ted Turner's autobiography. Turner's wealth was primarily a function of receiving resources from the Patriciate, which he managed well. He was loyal and gave back, becoming a globalist to maintain connections and expand his own holdings world-wide. The resources he recieved were in the form of deals on property, mergers, loans, and so on.</p>
            <p>Domhoff more abstractly argues for the Patriciate using the empirical evidence of the fact that the Fortune 500 corporate class is highly connected. They have numerous social clubs that have rotating members, making the class as a whole very much look like a social club. Ultimately, a class of about 6000 people at most controls most of the economy, and they are all friends with each other. Imagine being a natural elite, and either being locked out by these people or brought in. The difference it would make!</p>
            <p>There is, of course, still inter-elite conflict. It may be that credit primarily functions to prevent civil-war. A super-majority of the Patriciate must believe that a State expansion into their lives is just and good on net, or they may all rebel, resulting in State overthrow and the destruction of whatever faction was initiating an attack on another.</p>
            <h2>Egoism and Sociality as the Two Faces of Power, and the Benevolent King</h2>
            <p>Jouvenel suggests that Power often appears in two ways to people: as egotistical and parasitic, or as altruistic and benevolent. This is because it is often in the interest of Power to improve its subjects lives. Jouvenel believes that egoism is the primary driver of Power, but that when Power is secure, as in the person of a King, the ego will come to be identified with the subjects.</p>
            <p>In this way, stable and responsible power in the form of a King might be preferable to democracy. Moldbug is known to have stolen this argument of his from Jouvenel. There's another argument Moldbug stole in this book: the inverse ratio of power's extent and security. A secure, ego-identified King may have no reason to toxically expand. It is noted that rebellions generally happen against weak sovereigns; power is up for grabs after all. Jouvenel seems to want a Moldbuggian King as an executive of a Patriciate which vigorously believes in natural law and their right to be free from State interference.</p>
            <h2>Limited Power and Natural Law</h2>
            <p>Jouvenel wants juridicial defense, Mosca's term for when the interests in the Patriciate balance each other. Since they are balanced and evenly competing, there is liberty from the State and society becomes a de facto patchwork. Jouvenel believes the way to achieve this is by somehow getting the Patriciate to believe in the natural law and some form of divine sovereignty. They can either be hedonists that allow constant State expansion until all private interests are subjugated to yet another horribly absolutist Caesar, or they can wake up and realize that it would be a better equilibrium to have a vigorously limited State bounded by natural law, a law which is not alterable by the whims of Power.</p>
            <p>Quite a lot of pages are devoted to this sentiment but it is the weakest part of the book scientifically speaking so I shall stop here. Essentially, I do not agree that the Patriciate can be persuaded. I think they know their hedonism is a dangerous game, and that these libertarian elites Jouvenel wishes for must be altruistic hereditary libertarians. A Patriciate full of natural hedonists will always gambol away the future even if it is clear that they are indeed gamboling when they degrade the political formula to satisfy their momentary whim. They care very little for the future, especially that beyond their lifespan, and are essentially behaving rationally in that context, since it's pretty obvious if expansion will come back to bite you (if it frequently did, they would learn to stop expanding).</p>
            <h2>Conclusion</h2>
            <p>Jouvenel is a broad, multifaceted thinker. His core theory is the theory of the degradation of the political formula in the service of elite inter-conflict via the HLvM mechanism. Throughout his book, he draws heavily from Pareto, Michels, and especially Mosca. Jouvenel cares deeply for the health of the political formula and for juridicial defense, two major concepts from Mosca. At the same time, Paretian elite recirculation figures in the background of his theory, as well as components of Michels' Iron Law of Oligarchy, especially with regards to the idea of the society within the society and the impossibility of democracy beyond small primitive tribes.</p>
            <p>Though he may have tried to hide the influence of these thinkers on him (he never cites them or uses their terms!), Jouvenel is essentially a capstone of the school of pre-WWII continental elite-theory. Though the controversy on memetics between Pareto and Mosca remained unresolved, with Jouvenel siding with Mosca, Jouvenel nonetheless serves to adeptly merge the strands of thought which constituted continental elite theory, forming his own original, cohesive grand theory in the process.</p>
            <div className='author'>
                    <p>Henry Bearcroft, June 25, 2022</p>
            </div>
            <a href={michels}><p><i>Download PDF</i></p></a>
            </div>
        </div>
    )
}