import React, { useState, useEffect } from 'react';
import { renderPost } from '../api/index';
import { useParams } from 'react-router-dom';
import { getPostsByPageApi } from '../api/index';
import "../css/Michels.css"


export default function DynamicPage() {
    const { page_name } = useParams();
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        const getPosts = async () => {
            try {
                const response = await getPostsByPageApi(page_name);
                console.log(response.data);
                setPosts(response.data);
                console.log(response);
              } catch (error) {
                console.error('Error:', error);
              }

        };
        getPosts();
      }, []);


     return (
        <div className='Writings'>
            <div className='Writings-text'>
                <h1 id='Alpha'>ALPHABETICAL</h1>
                {posts.sort((a, b) => a.title.localeCompare(b.title)).map(post => (
                <div key={post.id} className='LinkAndSummary'>
                    <h1 id='myh1'><a href={`/post/${post.title_short}`}>{post.title}</a></h1>
                    <p id='p1'> {post.description} </p>
                    <br></br>
                </div>
                 ))}
            </div>
        </div>
    );
}
