import "../css/Michels.css"

export default function Writings ()
{
    /*
    return (
        
        <div className='Writings'>
            <div className='Writings-text'>
                <h1 id='Alpha'>CHRONOLOGICAL</h1>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/GeneticTheory">Genetic Theory of the French Revolution</a></h1>
                    <p id='p1'>May 24, 2022: Viewing the French Revolution as a biological phenomenon. </p>
                    <p id="p2"><a href="https://www.notupyet.com">Youtube</a>&ensp;<a href="https://www.notupyet.com">Odysee</a>&ensp;<a href="https://exousiology.substack.com/p/genetic-theory-of-the-french-revolution?s=w">Substack</a></p>
                    <br></br>
                </div>
            </div>
        </div>
        
    )
    */
   return (
    <p>Down for maintenance!</p>
   )
}