import React, { useState, useEffect } from 'react';
import { renderPost } from '../api/index';
import { useParams } from 'react-router-dom';
import "../css/Michels.css"
import { getPagesApi, addPageApi, deletePageApi } from '../api/index';
import '../css/Accounts.css'


export default function AddPages() {
    const [pages, setPages] = useState([]);
    const [toAdd, setToAdd] = useState('');
    const [pageType, setPageType] = useState('dynamic'); 
    useEffect(() => {
        const getPages = async () => {
            try {
                const response = await getPagesApi();
                console.log(response.data);
                setPages(response.data);
              } catch (error) {
                console.error('Error:', error);
              }

        };
        getPages();
      }, []);


     const addPage = async () => {
        const token = localStorage.getItem('authToken');
        const data = {
            token: token,
            name: toAdd,
            type: pageType,
        };
        try {
            const response = await addPageApi(data);
            console.log(response.data);
            window.location.reload();
          } catch (error) {
            console.error('Error:', error);
          }
     };

     const deletePage = async (pageId) => {
        const token = localStorage.getItem('authToken');
        const data = {
            token: token,
            id: pageId,
        };
        try {
            const response = await deletePageApi(data);
            console.log(response.data);
            setPages(pages.filter(page => page.id !== pageId)); // Update the pages state to remove the deleted page
            window.location.reload();
        } catch (error) {
            console.error('Error:', error);
        }
    };

     return (
        <div>
            <input 
                type="text" 
                value={toAdd} 
                onChange={(e) => setToAdd(e.target.value)} 
                placeholder="Enter page name"
            />
            <div>
                <label>
                    <input 
                    type="radio" 
                    value="dynamic" 
                    checked={pageType === 'dynamic'} 
                    onChange={() => setPageType('dynamic')} 
                    />
                    Dynamic
                </label>
                <label>
                    <input 
                    type="radio" 
                    value="static" 
                    checked={pageType === 'static'} 
                    onChange={() => setPageType('static')} 
                    />
                    Static
                </label>
            </div>
            <button onClick={addPage}>Add Page</button>     
            <ul>
                {pages.map((page, index) => (
                    <li key={index}>
                        {page.name}
                        <button className="delete-button" onClick={() => deletePage(page.id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}
