import "../../css/Michels.css"
import michels from "../../pdfs/verifyingpareto1.pdf";
var Latex = require("react-latex")

export default function Mosca ()
{
    return (
        <div className='Michels'>
            <div className='Michels-text'>
            <h1 id='essayTitle'>Verifying Pareto</h1>
            <h2>Introduction</h2>
            <p>Pareto makes interesting claims in his magnum opus, <i>The Mind and Society,</i> but provides no quantitative evidence, due to the fact that such evidence did not exist in his day. Instead, he opted for historical anecdote. Despite this epistemic shortcoming, his hypotheses are compelling and deserve attention. This essay will address these hypotheses quantitatively.</p>
            <h2>Class I and Class II Residues</h2>
            <p>Pareto claims that in elite classes there are members with varying amounts of "Class I" and "Class II" residues. These are essentially two clusters of mental traits. Class I-tilted people are more open, higher IQ, more deceptive, and more leftist. Class II people are less intelligent and open, and more religious and patriotic. To quote my earlier summary of Pareto:</p>
            <q id="quote1"><i>The most important of these are class 1 and class 2 because these are the only ones he refers to in his social analysis. Class 1 residues are simple enough -- they come down to curiosity, openness, irreligiousity, risk-taking and intelligence in Pareto's final account of his system. His evidence, as well as probably anybody's personal experience, shows that these residues are deep parts of our psyche not motivated by other fundamental drives plus reasoning. His evidence fails, however, to at all correlate these different aspects, or to sample the population as to establish how the expression of these residues vary between people. This matter will be investigated using modern psychological evidence near the end of this writing.</i></q>
            <br></br>
            <q id="quote1"><i>His class 2 residues have to do with attachment to the family, the home, and by extension, the nation. They also involve obedience to tradition -- it is unclear exactly how this tradition is instilled in people or what power this gives ``derivations.'' Pareto's treatment of this is vague and far too short. At times Pareto seems to think that these residues negatively correlate with Class 1 residues, that people tend to be either un-open, unintelligent, averse to risk, and attached to one's family and homeland, or else one is open, intelligent, irreligious, risk-tolerant, and hedonically unattached to family and nation. It is unclear, however, why loyalty to family and nation, religiosity, and risk-taking would correlate. I suspect Pareto is only partially correct here, and I hope to clear up this scheme and isolate what truth it has in my analysis of contemporary evidence below. For now this summary suffices; Pareto seems to play loose with his definitions anyway in his fourth volume so we will clarify these concepts more as needed soon. ... People with class 1 residues are greedy and short-sighted.</i></q>
            <p>What we have here is essentially a hypothesis about the correlation of various mental traits. Modern psychometrics attempt to measure much of what Pareto spoke of, and offers correlations between these measurements. After examining the data on this, it appears that Pareto was definitely onto something. The traits he speaks of do modestly correlate with each other in the directions he predicted.</p>
            <img src={require("./Pareto1Img/pareto1.png")} width="600" height="350"></img>
            <p>The above table is from a 2013 meta-analysis [1]. It shows that the IQ-religiosity correlation is about r = -0.20. This is small but significant. Emil Kirkegaard found [2] a correlation of about r = -0.40 with n = 67,000. This may be more trustworthy since Kirkegaard's religiosity questionaire was likely less retarded than that of academic psychologists but it is hard to tell.</p>
            <img src={require("./Pareto1Img/pareto2.png")} width="600" height="375"></img>
            <p>Above is data from a 1999 study [3]. IQ correlates with  openness at r = 0.50 and income at about r = 0.40 (the best studies here say r = 0.50). Pareto claimed that smarter, more open and creative people were less religious and traditional, and secured their power through income acquisition. So far it seems that higher IQ people are less religious, more open (i.e. creative, less prone to what Pareto called Class II group-persistences), and are better at acquiring income. As elite IQ increases, so would openness, and religiosity would decrease. Pareto hypothesizes in his cycle theory that the ability to acquire wealth becomes a selection mechanism for the elite, and in turn Class I residues increase. Just from this data, as elite income increases, we would expect IQ and openness to increase signficantly, and religosity to decrease significantly. Specifically, if income increased by 1 SD, IQ might increase by 0.50 SD, openness by 0.25 SD, and religiosity might decrease by 0.10 SD. Tail effects could produce more extreme phenomena than otherwise expected from these effect sizes if these traits are normally distributed. Consider also that in the Pareto cycle, income might go up by even 2 SD during the Class I influx phase, producing potentially a 1 SD increase in IQ, a 0.50 increase in openness, and a 0.20 decrease in religiosity.</p>
            <p>Another study found: [6]</p>
            <q id="quote1"><i>Significant positive phenotypic correlations with IQ were seen for agreeableness (r = 0.21) and openness to experience (r = 0.32). A negative correlation emerged for neuroticism and IQ (r = 0.10). Genetic factors explained (nearly) all of the covariance between personality traits and IQ. Genetic correlations were 0.3–0.4 between IQ and agreeableness and openness. The genetic correlation between IQ and neuroticism was around 0.18. Thus, personality and IQ did not appear to be independent dimensions, and low neuroticism, high agreeableness and high scores on openness all contributed to higher IQ scores.</i></q>
            <p>This provides some evidence for Pareto's "foxes vs. lions" conjecture. The Class I residue foxes are supposedly more agreeable. A 2014 study with n = 3618 found a significant r = 0.20 relationship between "psychopathy" and conservatism, further contributing to this idea [7].</p>
            <p>Next we get into Haidt's moral schema. It is very useful here because it conforms to Pareto's hypotheses. In it, leftists are essentially hedonists deficient in patriotism and sacred values. This aligns with Pareto's description of Class I vs. Class II elites.</p>
            <p>To begin, according to a 2022 study, [4] Haidt's moral foundations are separately heritable along two dimensions, essentially corresponding to Class I and Class II residues:</p>
            <q id="quote1"><i>Moral Foundations Theory (MFT) predicts that moral behaviour reflects at least five foundational traits, each hypothesised to be heritable. Here, we report two independent twin studies (total n = 2020), using multivariate multi-group common pathway models to test the following three predictions from the MFT: (1) The moral foundations will show significant heritability; (2) The moral foundations will each be genetically distinct and (3) The clustering of moral concerns around individualising and binding domains will show significant heritability. Supporting predictions 1 and 3, Study 1 showed evidence for significant heritability of two broad moral factors corresponding to individualising and binding domains. In Study 2, we added the second dataset, testing replication of the Study 1 model in a joint approach. This further corroborated evidence for heritable influence, showed strong influences on the individualising and binding domains (h2 = 49\% and 66\%, respectively) and, partially supporting prediction 2, showed foundation-specific, heritable influences on Harm/Care, Fairness/Reciprocity and Purity/Sanctity foundations. A general morality factor was required, also showing substantial genetic effects (40\%). These findings indicate that moral foundations have significant genetic bases. These influenced the individual foundations themselves as well as a general concern for the individual, for the group, and overall moral concern.</i></q>
            <img src={require("./Pareto1Img/pareto3.png")} width="600" height="400"></img>
            <p>From a Jordan B. Peterson study [5], intellect negatively correlates with the right-wing moral foundations Authority-Respect and Purity-Sanctity at r = -0.25. These are analogous to Class II group-persistence and religiosity residues. If IQ increased in the elite by 1 SD, we could expect a 0.25 SD decrease in Purity-Sanctity and Authority-Respect, in other words.</p>
            <img src={require("./Pareto1Img/pareto3.jpg")} width="600" height="400"></img>
            <p>Finally, from a 2018 study, [8] CRT, a cognitive measure which correlated with IQ are r = 0.44, was significantly lower in people with more rightist moral foundations.</p>
            <p>The evidence suggests that Pareto was onto something with his Class I and Class II residue scheme. If income increased by 1 SD, IQ might increase by 0.50 SD, openness by 0.25 SD, and religiosity might decrease by 0.10 SD, while Purity-Sanctity and Authority-Respect would decrease by 0.125 SD. Tail effects could produce more extreme phenomena than otherwise expected from these effect sizes if these traits are normally distributed. Furthermore, we should not rule out the possibility that elites are actually directly selected on some or all of these traits in a Class I influx period. We have also seen that, genetically, Liberal and Conservative moral foundations, which might be termed Class I and Class II moral foundations, seem to vary independently; in addition, IQ genes seemed to cause an increase in openness as well as agreeableness, lending much credit to the lions vs. foxes conjecture, wherein Class I elites are foxes who are more agreeable, sly, deceptive, and less prone to direct confrontation and violence.</p>
            <h2>The Pareto Cycle: Some Preliminary Evidence from Turchin</h2>
            <p>On top of his residue scheme, Pareto advances his cycle theory, which states, basically, that as parvenus enter the governing elite due to merit, Class I residues increase; eventually there are too many foxes, and too much infighting, and not enough action, and a Caesar takes over and closes off the elites. Class I residues decrease for some reason, perhaps regression to the mean, and Class II residues come to predominate. Eventually the governing elite cannot compete with a new, burgeoning, high Class I residue counter elite, and there is a revolution and the doors to the governing elite are once again thrown open to aspiring parvenus. Then the cycle repeats.</p>
            <p>This hypothesis is hard to test without large amounts of historical data; luckily, Peter Turchin and his cliodynamicists focus on just this type of thing. Consequently, I did some preliminary skimming of Turchin to see if he concurs with Pareto (eventually I will read his work in full, after I get through my current huge reading list). The results were promising. I will quote a <a href="https://www.lesswrong.com/posts/2weRdcvqANDq3zdPH/book-review-secular-cycles">book review</a> on Turchin's work <i>Secular Cycles</i> at length:</p>
            <q id="quote1"><i>But nobles go through a related process. As a cycle begins, their numbers are low. As time goes on, their population expands, both through natural reproduction and through upward mobility. Eventually, there are more nobles than there are good positions and the nobles form “rival patronage networks” to fight for the few remaining good spots. The state goes from united (or at least all nobles united against the peasants) to divided, with coalitions of nobles duking it out (no pun intended). This can lead either to successful peasant rebellion, as some nobles support the peasants as part of inter-noble power plays, or just to civil war. There are about a hundred years of unalloyed growth, as peasant and noble populations rebound from the last disaster. During this period, the economy is strong, the people are optimistic and patriotic, and the state is strong and united.</i></q>
            <br></br>
            <q id="quote1"><i>After this come about fifty years of “stagflation”. There is no more room for easy growth, but the system is able to absorb the surplus population without cracking. Peasants may not have enough land, but they go to the city in search of jobs. Nobles may not have enough of the positions they want, but they go to college in order to become bureaucrats, or join the retinues of stronger nobles. The price of labor reaches its lowest point, and the haves are able to exploit the desperation of the have-nots to reach the zenith of their power. From the outside, this period can look like a golden age: huge cities buzzing with people, universities crammed with students, ultra-rich nobles throwing money at the arts and sciences. From the inside, for most people it will look like a narrowing of opportunity and a hard-to-explain but growing sense that something is wrong. After this comes a crisis. The mechanisms that have previously absorbed surplus population fail. Famine and disease ravage the peasantry. State finances fall apart. Social trust and patriotism disappear as it becomes increasingly obvious that it’s every man for himself and that people with scruples will be defeated or exploited by people without.</i></q>
            <br></br>
            <q id="quote1"><i>After this comes the depression period (marked “intercycle” on this graph, but I’m going to stick with the book’s term). This graph makes it look puny, but it can last 100 to 150 years. During this period, the peasant population is low, but the noble population is still high. This is most likely a period of very weak or even absent state power, frequent barbarian invasions, and frequent civil war. The peasant population is in a good position to expand, but cannot do so because wars keep killing people off or forcing them into walled towns where they can’t do any farming. Usually it takes a couple more wars and disasters before the noble population has decreased enough to reverse elite overproduction. At this point the remaining nobles look around, decide that there is more than enough for all of them, and feel incentivized to cooperate with the formation of a strong centralized state.</i></q>
            <p>The first paragraph clearly describes Pareto's Class I influx phase. The last paragraph describes the rise of a Caesar or an autocratic selection system which is closed off. The Class II phase is not as clearly described and appears to be presented as a period of inter-elite conflict instead. Still, this is only a book review. Eventually I will personally read Turchin's book and write my own review, addressing its fit to Pareto and Jouvenel's cycle theories.</p>
            <h2>Hypotheses on Memetics</h2>
            <p>Pareto spends a lot of time on memetics, arguing that ideas mostly don't matter and are downstream of genes and material influences. This is at odds with many kosher histories which uncritically assume that ideas are the cause of everything. It is possible that scholars are predisposed to this sentiment because it makes them feel important, whereas Pareto would tell them that they are only pawns, going along with the genetic and material sentiments of the time, like Voltaire, or condemning themselves to relative irrelevance otherwise, like Lucian (both writers were essentially anti-Christian skeptics).</p>
            <p>Pareto does not provide much to work off of other than the general idea that residues and sentiments are dominants over derivations (to put things in his terms). He gives copious amounts of examples of pseud derivations being stupid nonsense, and of people seeming to be more influenced by things prior to them, but admits from time to time that derivations and "form" (as opposed to substance) can have some independent effect on behavior.</p>
            <p>Because of the scientific inadequacy of Pareto's work on this topic, I will provide the specific hypotheses for him while keeping in line with the spirit of his views, which I tend to agree with.</p>
            <p>It's not surprising that Pareto's statements on this topic were relatively vague; it is nontrivial to hypothesize about these things. Even today there is not much research on "memetics" and part of the reason for that is that very few people have explicitly conceptualized the territory here. Consequently, it will be useful to merely come up with potentially study designs.</p>
            <p>First I will share the model I am currently operating under. In this model, apparently-true ideas can truly influence behavior, but nonsensical derivations cannot (although they can reflect the will of someone with power, causing underlings to submit). Let me expand more: there are verifiable ideas which give you predictions about the natural world; these influence behavior with respect to pleasure pursuit as they give you your map for how to attain pleasure or satisfy other residues or whatever internal drives motivate people. If an idea does not give you a prediction about the natural world, then it does not effect your map of the world and consequently your strategy for attainment of desire is unchanged. A sentence can either convey verifiable information in its literal interpretation, or it can convey verifiable information about its sender, or neither. Consequently, where a sentence conveys no verifiable information in its literal words, if it effects behavior it is because it conveyed information about the  sender which caused the receiver to change behavior due to obedience. If a sentence conveys no information, it cannot effect behavior any more than random noise.</p>
            <p>We also have the culture equation:</p>
            <Latex>{`$$C = E[P] = E[G + E + M]$$`}</Latex>
            <p>Where G is genetics, M is memetics, and E is other environment. We can begin by asking what proportion of variation in elite behavior variation in memetics explains at some time, such as now. In other words, we want to know:</p>
            <Latex>{`$$\\frac{Var(M)}{Var(P)} \\leq 1 - \\frac{Var(G + E)}{Var(P)} = 1 - h^2 + \\frac{Var(E)}{Var(P)}$$`}</Latex>
            <p>In other words, just estimating the heritability of elite political behavior puts a lower bound on the influence of memetics. If the heritability of their behavior is, say, 0.80, then memetics can explain no more than 0.20 of the variance in behavior. If material factors such as business interests can explain 0.10 of the variance in behavior, then memetics is down to at most 0.10 of the variance and so on.</p>
            <p>An amazing 2021 twin study anticipated this: [9]</p>
            <q id="quote1"><i>Twin studies function as natural experiments that reveal political ideology’s substantial genetic roots, but how does that comport with research showing a largely nonideological public? This study integrates two important literatures and tests whether political sophistication – itself heritable – provides an “enriched environment” for genetic predispositions to actualize in political attitudes. Estimates from the Minnesota Twin Study show that sociopolitical conservatism is extraordinarily heritable (74\%) for the most informed fifth of the public – much more so than population-level results (57\%) – but with much lower heritability (29\%) for the public’s bottom half. This heterogeneity is clearest in the Wilson–Patterson (W-P) index, with similar patterns for individual index items, an ideological constraint measure, and ideological identification. The results resolve tensions between two key fields by showing that political knowledge facilitates the expression of genetic predispositions in mass politics.</i></q>
            <p>From my essay on political agency, the elite are highly intelligent, and others have directly found them to be informed [10], which should be expected from the fact that the bottom 80 percent of Americans are basically retarded, and elites are not. When you are 120+ IQ, it is not hard to be in the top 20 percent of informed people. 55 or 60 percent of the country don't even vote. Consequently, variation in memetic exposure explains at most 26 percent of variation in elite behavior, and probably less than that since we have specifically singled out the elite as informed, i.e. they face low variation in information or memetics.</p>
            <p>This alone is enough to accept Pareto's gist. I will leave with some ideas to go further. First, if we can show that material incentives explain 25 percent of the variation in elite political phenotype, then memetics explains none of it. For the effect of memetics between elite groups (across time basically), we can do  studies where we literally go through Hobbes, Locke, Rousseau, Marx, etc (there are a finite number of pseuds) and see if people learning about them and hypothetically agreeing with them will hypothetically change their political preferences. Then do same for "apparently true ideas" like HBD and compare the effect sizes. This will give us estimates of the effects of these writings and ideas on elites who are exposed to them en masse upon release. I hypothesize that the pseuds will have little to no effect, and apparently true ideas like HBD would have, on average, a mild to moderate effect.</p>
            <h2>Afterthoughts</h2>
            <p>Pareto's general ideas have all been moderately well verified here. I wanted to give thanks to <a href="https://theuntangler.wordpress.com/">Sebastian Jensen</a> and <a href="https://werkat.substack.com/">Werkat</a> for helping me compile these studies. Also I wanted to mention a study showing a correlation of r = -0.48 between sensation seeking (basically a measure of hedonism) and conservatism. The p value was quite low, i.e. less than 1 percent,  but the study was on 43 music students in 1985, so I didn't cite it in the main body [11]. Still, it converges with the evidence above is interesting with respect to my idea that left vs. right = hedonism vs. altruism.</p>
            <p>[1] Zuckerman, M., Silberman, J., & Hall, J. A. (2013). The relation between intelligence and religiosity: A meta-analysis and some proposed explanations. Personality and social psychology review, 17(4), 325-354.</p>
            <p>[2] Kirkegaard, E. O., & Lasker, J. (2019). Intelligence and religiosity among dating site users. Psych, 2(1), 25-33.</p>
            <p>[3] Judge, T. A., Higgins, C. A., Thoresen, C. J.,& Barrick, M. R. (1999). THE BIG FIVE PERSONALITY TRAITS, GENERAL MENTAL ABILITY, AND CAREER SUCCESS ACROSS THE LIFE SPAN. Personnel Psychology, 52(3), 621–652. doi:10.1111/j.1744-6570.1999.tb00174.x</p>
            <p>[4] Zakharin, M., & Bates, T. C. (2022). Testing heritability of moral foundations: Common pathway models support strong heritability for the five moral foundations. European Journal of Personality, 08902070221103957.</p>
            <p>[5] Hirsh, J. B., DeYoung, C. G., Xu, X., & Peterson, J. B. (2010). Compassionate liberals and polite conservatives: Associations of agreeableness with political ideology and moral values. Personality and Social Psychology Bulletin, 36(5), 655-664.</p>
            <p>[6] Bartels, M., van Weegen, F. I., van Beijsterveldt, C. E. M., Carlier, M., Polderman, T. J. C., Hoekstra, R. A., & Boomsma, D. I. (2012). The five factor model of personality and intelligence: A twin study on the relationship between the two constructs. Personality and Individual Differences, 53(4), 368–373. doi:10.1016/j.paid.2012.02.007</p>
            <p>[7] Lilienfeld, S. O., Latzman, R. D., Watts, A. L., Smith, S. F., & Dutton, K. (2014). Correlates of psychopathic personality traits in everyday life: Results from a large community survey. Frontiers in psychology, 5, 740.</p>
            <p>[8] Kuczma, M. (2018). Examining Moral Foundations and Thinking Styles.</p>
            <p>[9] Kalmoe, N. P., & Johnson, M. (2021). Genes, ideology, and sophistication. Journal of Experimental Political Science, 1-12.</p>
            <p>[10] Kinder, D. R., & Kalmoe, N. P. (2017). Neither liberal nor conservative. In Neither Liberal nor Conservative. University of Chicago Press.</p>
            <p>[11] Ruth Glasgow, M., Cartier, A. M., & Wilson, G. D. (1985). Conservatism, sensation-seeking and music preferences. Personality and Individual Differences, 6(3), 395–396. doi:10.1016/0191-8869(85)90065-0</p>
            <div className='author'>
                <p>Henry Bearcroft, August 26, 2022</p>
            </div>
            <a href={michels}><p><i>Download PDF</i></p></a>
            </div>
        </div>
    )
}