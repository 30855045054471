import "../css/Michels.css"

export default function ReadingGroup ()
{
    return (
        <div className='Michels'>
            <div className='Michels-text'>
            <p>There is a reading group for sociological texts. We are currently reading the website and various works of G. William Domhoff. If you are interested in joining the book club, please message me on Twitter, Discord, or Telegram.</p>
            <p>Past books: </p>
            <ul>
                <li>Left and Right: The Significance of a Political Distinction by Norberto Bobbio</li>
                <li>Fascism: The Career of a Concept by Paul Gottfried</li>
                <li>Understanding Nazi Ideology by Carl Müller Frøland</li>
                <li>The Marx-Engels Reader</li>
                <li>The Machiavellians by James Burnham</li>
                <li>Nemesis by C.A. Bond</li>
                <li>Who Rules America? by G. William Domhoff</li>
                <li>Studying the Power Elite: 50 Years of Who Rules America</li>
                <li>The Ruling Class by Gaetano Mosca</li>
                <li>Political Parties by Robert Michels</li>
                <li>The Mind and Society by Vilfredo Pareto</li>
                <li>On Power by Bertrand de Jouvenel</li>
                <li>The Managerial Revolution by James Burnham</li>
                <li>Think Tanks in America by Thomas Medvets</li>
                <li>The Rise and Fall of Elites by Vilfredo Pareto</li>
                <li>The Power Elite by C. Wright Mills</li>
                <li>The Website and Works of G. William Domhoff</li>
                <li>Power: A Radical View by Steven Lukes</li>
                <li>Great Founder Theory: 2020 Manuscript by Samo Burja</li>
            </ul>
            <p>Current reading list: Funding Feminism, Stalin, Dictator's Handbook, Power: A New Social Analysis, Manufacturing Consent, Influence: the Psychology of Persuasion, Public Opinion, Propaganda by Bernays, Racial Integration in Corporate America 1940-1990, Constructing Affirmative Action, The Pursuit of Power by McNeil, Leviathan and Its Enemies, The Populist Delusion, Giants: The Global Power Elite, An Economic Theory of Democracy, The Calculus of Consent, The Logic of Collective Action, The Politics of Bureaucracies & Economic Hierarchies, Public Choice III, Capitalism: Its Origins and Evolution as a System of Governance </p>
            </div>
            <div className='Michels-text'>
            <p>There is also a study group for mathematics and mathematical science relating to exousiology, especially microeconomics and behavior genetics. We are currently studying All of Statistics by Larry Wasserman alongside the MIT Opencourse Probability and Statistics course. If you are interested in joining this group, please message me on Twitter, Discord, or Telegram.</p>
            <p>Past texts: </p>
            <ul>
                <li>Behavioral Genetics by Robert Plomin</li>
                <li>Linear Algebra Done Right by Sheldon Axler</li>
                <li>Price Theory by Steven Landsburg</li>
            </ul>
            <p>Current reading list: Graph Theory and Its Applications, MIT Opencourse ODEs, Some PDEs text not yet determined, Microeconomic Theory by Nicholson, Intermediate Microeconomics by Varian, Some game theory text, Potentially real analysis, topology, and abstract algebra to prepare for study of complexity theory, though this may be irrelevant to exousiology; potentially some complexity theory text.</p>
            </div>
        </div>
    )
}