import "../../css/Michels.css"
import powersingularity from "../../pdfs/powersingularity.pdf";
var Latex = require("react-latex")

export default function PowerSingularity ()
{
    return (
        <div className='Michels'>
            <div className='Michels-text'>
            <h1 id='essayTitle'>The Power Singularity</h1>
            <p>We live on the threshold of the point of no return in regards to who holds power over the destiny of humanity. Over the last 300 years, that power has transferred from wealthy Anglo-Saxon capitalist cliques to half-Jewish, half-otherwise-gentile oligarchies. The fruits of these oligarchies are observed all around in the year of our Lord 2020: the abolition of sexual morality, the family, and the white race in favor of brown hedonism guided by Semitic intelligence.</p>
            <p>I firmly believe that the success of the current powers means the destruction of humanity, and that these powers will be unstoppable by the end of the 21<sup>st</sup> century due to fatal technological developments. For there are developments that are coming fast which constitute a Power Singularity, a point of no return the human race will enter into, and which has an event-horizon that cannot be escaped. The technologies that will most likely constitute this singularity include robotics, AI, and bio-engineering. To put it simply, by 2100 Sovereignty will probably have fine control over the direction of the gene pool, will have automatic AI surveillance mechanisms that automatically record and analyse everyone’s behavior, and the human police will be abolished and at last will be totally divorced from power, replaced with robot armies.</p>
            <p>Already things like Clearview AI (created by NRx transsexual Hoan Ton That and funded by Moldbug funder Peter Thiel) are in existence. It can take footage from any public security camera and automatically identify a person based off of their government identification photos and publicly available social media posts. Bark AI is another system of surveillance that was allegedly created so that lazy and invasive parents could spy on their grown children. It monitors all messages sent and received on social media and can automatically identify hate speech, sexual content, “bullying,” and more. In other words, the NSA is no longer encumbered by the inability to analyse anywhere near all of the data they collect. It is now possible to have AI classify all incoming data, and to automatically act on it directly or by alerting human overseers. In the next few decades this will be refined and censorship will be automatic as will hate speech enforcement. If it is possible that my real name is not on a list already for white advocacy, for whatever children I manage to have in this ant-farm that will not be a possibility. In fact, it will be trivially easy to drive the right off of the internet entirely.</p>
            <p>In the last 20 years, progress on drones and robotics has taken off. The media fails to report on this but the reality is that autonomous military robots who are resistant to gunshots and other hazards of war are coming quickly. The effect of this is not hard to understand. Whatever freedom the common man is accorded today for fear of his weaponry and his integral part in the armed forces will no longer be necessary. The police will not be able to decide to not enforce gun control or hate speech laws (not that that would happen anyway, since police are 100 IQ and domesticated) because a Robocop will simply be sent to the last social conservative’s pod to “reeducate” him.</p>
            <p>Whatever human underclass that will still exist will become hopelessly more slavish due to biological engineering. While they may be made more intelligent, their temperaments will be made more sub-human than the average of today, which is already quite a problem. There will not even be an impulse of rebellion in these “people.” Nor will there be anything higher than the will to pleasure. People like myself, rare as we are, will be totally exterminated. If I were born 100 years later my parents would have simply picked the most obedient embryo. In the beginning this will be sold to parents as a good childhood behavior genotype. Based off of the temperament PGS, the doctor will say, “this is the one that will be the least likely to commit crime or get in trouble at school.” This is because it’s probably true that thought criminals are just intelligent street criminals. To think independently there must be not only intelligence, but an independence of spirit – a defiant temperament. This is not to say that most thought criminals are real criminals, only that they have a will that fails to truly subordinate to the power structure. And so this is a factor in committing crime, and that is how the power structure will sell the extermination of this precious temperament. Only among the elite, which consist of a number of people less than the Dunbar number, will this not be the case. They will select for genotypes fit for the elite lifestyle. While they complete the project of turning the underclass into farm animals, it is possible that they choose for their own children to have more active and independent temperaments. This not happening is one of the scenarios in which the human race collapses and dies. There will be no one even semi-fit to rule and accordingly there will be no more civilization. The human mass will find itself incapable of organizing itself for the lack of talent. At this point we may hope that good machines take over.</p>
            <p>But let us imagine it is 2120 and the Jewish elite (note: it’s really only half Jewish, but the Jewish component is significant and obscuring it would be cringe) have not so quickly destroyed themselves. Whites may be totally extinct, in which case the human race will have begun its final course towards extinction. Otherwise we may imagine ourselves as a young white man in the US. The white population is at about 10% and declining; the extinction won’t be complete for a few more generations. White people will live in ghettos, which will be comparatively nice placed to live but which will still be constantly taxed by robocops and corrupted by outside propaganda. There will be surveillance cameras everywhere just like there are now, but they will be monitored by AI. The expression of any socially conservative ideas will lead to automatic punishment, ranging from a fine to death.</p>
            <p>There will be at least 16 years of required “education” starting around age 5, similar to today. This education will consist of spirit destroying tasks such as learning to use Microsoft Office and how to be a good 4D spreadsheet data entryist (these tasks will be fully automated, making this even more spirit destroying). At least half of the curriculum will consist of false history and sociology. White people and Adolf Hitler will still be demonized despite the race being terminally ill. History will be framed as Jewish (Jews will wear special patches to distinguish themselves from legally-disabled white people) and brown. It will be considered racist to mention white inventions.</p>
            <p>The internet will be totally de-anonymized and auto-monitored by AI. A webcam and microphone will be required to use the internet; this will have been legally mandated by the SAFETY Act after the Great Racial Slur Attack of 2101 (in which a white man was able to call over 1000 black people the n-word anonymously [strangely despite the longstanding auto-censorship measures] and was never brought to justice).</p>
            <p>Our hypothetical white man won’t even care because he will be essentially subhuman, the product of bio-engineering for poor temperament. He may even be intelligent, so that he may wageslave at a BS job (all real production will be performed by AI). But he will have no soul (let that be defined as an independent affinity towards the good). He will be like the average 100 IQ male now, except somehow worse. All he will think about is having sex (outside of marriage of course, and half gay at least) and doing drugs and consuming product. He will be unable to question anything about his circumstances despite possibly having a high IQ. The virtual reality products will totally create his world for him. He will even believe that he has power since he’s still able to vote between two types of Democrat. His hobbies will include consuming media, smoking marijuana, injecting Substance D, getting topped by BBC, trading stocks, and playing with his cat.</p>
            <p>You would be correct to think that this sounds like the typical person in 2020. This is because a large portion of the population is already domesticated. What will occur is the extermination of the right-tail and a shifting of the average to the left. Progressives of today will be the robot-brains of tomorrow. Neocons will be the new progressives. Half-way outside-the-box rationalists will be the new Neocons (which will be on the edge of hatespeech laws). Anything else will not exist due to genetic engineering and laws dictating the illegality of such ideas.</p>
            <p>It will be the total death of the human soul. Instead of ruling the world, it will be destroyed. Already the human soul is gasping for breath, but without such technology it would be near impossible to actually finish it off.</p>
            <p>If the right people are not in power by the end of the century it will mean disaster for the human race. If the current group maintains power, unless they seriously transform themselves they will destroy everything. They are not equipped to lead. A single moment in modernity reveals that much. We must have rulers who are devoted to the life of life and the death of death. Not those who would waste so much energy on normalizing gay sex, genital mutilation, and ultimately pedophilia. Think about it like this: Star Wars and anime would basically be real right now if trad whypipo still ruled but instead we have race riots, white genocide, and gay pride. An elite willing to do this are poised to just outright start worshipping Moloch in the open except this time Moloch wants 20 million white babies every year and demands 80% of human energy be spent on activities that involve blood an feces such as scat, anal sex, injecting drugs, cannibalism, and genital mutilation. This is death and we will not survive it.</p>
                <div className='author'>
                        <p>Henry Bearcroft, September 7, 2020</p>
                </div>
            <a href={powersingularity}><p><i>Download PDF</i></p></a>
            </div>
        </div>
    )
}