
import "../css/Recent.css"
import MoldbugPt3 from "../pdfs/MoldbugPt3.pdf";
//import React, {useEffect, useState} from 'react';
//import axios from "axios";

//fuckyou
//123456
//fuckyou@gmail.com

export default function Recent ()
{

  //  const [listOfPosts, setListOfPosts] = useState([]);
  //      useEffect(() => {
  //          axios.get("http://localhost:3001/posts").then((response) => {
  //              setListOfPosts(response.data);
  //          } );
  //      }, []);
    return (
    <div className="Recent">
        <div className='Writings'>
            <div className='Writings-text'>
             <h1 id='Alpha'>RECENT</h1>
             <div className='LinkAndSummary'>
                    <h1 id='myh1'>August 26, 2022</h1>
                    <p id='p1'> New essay! <a href="/verifying-pareto-1">Verifying Pareto.</a> Also, posts on Think Tanks in America, The Power Elite, and Domhoff are forthcoming. These books explore the inner workings of the Patriciate and totally explode false ideas about who the sovereign class is, like the "Cathedral" narrative.</p>
                    <br></br>
                </div>
             <div className='LinkAndSummary'>
                    <h1 id='myh1'>July 28, 2022</h1>
                    <p id='p1'> New book review! <a href="/Managerial">The Managerial Revolution by James Burnham.</a></p>
                    <br></br>
                </div>
             <div className='LinkAndSummary'>
                    <h1 id='myh1'>July 26, 2022</h1>
                    <p id='p1'> Fixing my https key and my workflow so I can update this site more! </p>
                    <br></br>
                </div>
             <div className='LinkAndSummary'>
                    <h1 id='myh1'>June 25, 2022</h1>
                    <p id='p1'> Two new book reviews! <a href="/MindAndSociety">Pareto</a> and <a href="/OnPower">Jouvenel</a>. Also, see this extremely important post on <a href="/PoliticalAgency">political agency.</a> </p>
                    <br></br>
                </div>
             <div className='LinkAndSummary'>
                    <h1 id='myh1'>May 24, 2022</h1>
                    <p id='p1'> New video and blog post!  <a href= "https://www.youtube.com/watch?v=U8UYgYfNowM">Laughing at Moldbug's take on the Buffalo shooting</a> and <a href="/GeneticTheory">Genetic Theory of the French Revolution.</a> I have set up a Substack which mirrors my blog posts. See the about page for more information on this. Eventually I will add comments and a subscription option here, but for now I will upload links to my essays to the substack as well as full copies of my blog posts, which will be more common than my essays, since essays are defined by the fact that they require heavy research, while blog posts can be written quickly.</p>
                    <br></br>
                </div>
             <div className='LinkAndSummary'>
                    <h1 id='myh1'>May 11, 2022</h1>
                    <p id='p1'> New video!  <a href= "https://www.youtube.com/watch?v=XM-W99LxjWo">Who Has Power: Centralization and Coordination, Part 1.</a></p>
                    <br></br>
                </div>
             <div className='LinkAndSummary'>
                    <h1 id='myh1'>April 30, 2022</h1>
                    <p id='p1'> New writing!  <a href= "/Hypotheses">Some Useful Hypotheses for Historical Analysis.</a></p>
                    <br></br>
                </div>
             <div className='LinkAndSummary'>
                    <h1 id='myh1'>April 23, 2022</h1>
                    <p id='p1'> New video!  <a href= "https://www.youtube.com/watch?v=DNypn1GzNwg">Political Parties and the Iron Law of Oligarchy by Robert Michels: Summary and Review</a></p>
                    <br></br>
                </div>
             <div className='LinkAndSummary'>
                    <h1 id='myh1'>April 22, 2022</h1>
                    <p id='p1'> <a href= "/Dysgenics">New essay: Female Romantic Preferences are Dysgenic</a></p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'>April 17, 2022</h1>
                    <p id='p1'> <a href= "https://www.youtube.com/watch?v=reQfT2j7D3I">New video: Elon Musk's Attempted Twitter Takeover & The Iron Law of Oligarchy</a></p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'>April 15, 2022</h1>
                    <p id='p1'>Added <a href= "MichelsReview">review of Robert Michels' <i>Political Parties.</i></a> I also expanded the Mosca review to discuss his brief treatment of ruling class and social type. Updated the Reading Group page.</p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'>April 3, 2022</h1>
                    <p id='p1'>Started my <a href= "/bookrev">book reviews page</a> by adding the <a href = "/MoscaReview">Mosca review</a> I wrote today to it!</p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'>March 27, 2022</h1>
                    <p id='p1'>Spent all day pimping out this site. Check out the <a href="/writings">writings page!</a> New essays will be written in LaTeX, and a LaTeX2HTML converter that I wrote in Python allows me to paste that here. 
            LaTeX pdf downloads are given at the bottom of writing pages.</p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'>March 23, 2022</h1>
                    <p id='p1'>Part 3 of my grand summary of Moldbug is out, a work in progress that seeks to do Moldbug better than Moldbug himself. <a href={MoldbugPt3}>Link to part 3 of the Moldbug Summary: The Cathedral, its structure, origins, and motivations.</a></p>
                    <br></br>
                </div>
            </div>
        </div>
    </div>
    )
 /*
    return (
        <div>
            <p>Down for maintenance!</p>
        </div>
       );
       */
}

              
    //            {listOfPosts.map((value, key) => {return ( 
    //            <div className='LinkAndSummary'>    
     //               <h1 id='myh1'>{value.title}</h1>
     //               <p id='p1'>{value.postText}</p>
     //               <br></br>
     //           </div>)})}

