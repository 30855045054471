import "../../css/Michels.css"
import genetic from "../../pdfs/genetic.pdf";
var Latex = require("react-latex")


export default function Mosca ()
{
    return (
        <div className='Michels'>
            <div className='Michels-text'>
            <h1 id='essayTitle'>Genetic Theory of the French Revolution</h1>
                <h2>Against sub-scientific history</h2>
                <p>Typical history goes like this: “these people did these things because of these reasons.” The popular narrative of the French Revolution is that, roughly, “the National Assembly overthrew the King because of <a href="https://en.wikipedia.org/wiki/Causes_of_the_French_Revolution">the Enlightenment and the poor economy</a>.” Some historians are more rigorous than others; some write histories with no names, only Institutions and Ideas play a role. Others are more particular, but never are historians scientific.</p>
                <p>What is the explanation for this phenomenon? Is the historian’s job not to fully account for the changes which human society has passed through over time? The historian scoffs at prediction; he would rather tell a tale. His general attitude is, to quote Curtis Yarvin’s oft repeated line, “history is not a science. History is a narrative.” This attitude is not acceptable. I demand that history be a science. I condemn narrative weavers on the charge of Lying. Henry’s first law of history is henceforth, “thou shalt be scientific.”</p>
                <p>To see what we mean by this, let’s explain the historian’s behavior using his own methods. Perhaps the historian is a product of culture. Yes, that might be correct — the Herodotean brain worm thrives in the 4th dimension which exists in the mind of the historian! Due to its epic spiritual fight with the brain worm of Thucydides, no progress can be made. Historians are doomed to write subscientific histories until this great battle in the heavens ceases. It’s a Moloch thing, and egregore if you will. Decentralized. Emergent. Am I not so very subtle and intelligent?</p>
                <p>Perhaps that’s hogwash. Historical idealism? Try some historical materialism. Scientific history must not pay well. There’s no economic incentive for it. There obviously was for physics in the 17th century, but not history. Maybe I have data to back up these economic musings, maybe not. Who needs data when you have anecdotes?</p>
                <p>Of course Marx is babble. Memetics is cringe. More subtle minds understand the massive role of technology in shaping human behavior. Obviously scientific history will emerge precisely when man is capable of it. It must not be possible yet.</p>
                <p>Perhaps you don’t see what’s wrong with these examples. Maybe they sound reasonable to you. Surely if some evidence here and there were brought in to show that these are plausible effects, we would have a rather scientific theory on our hands upon accepting whatever mix of the three effects are found to be plausible. But you would be wrong. These three copes are so rife in the academy, and each of them shares the same fundamental flaw.</p>
                <p>This flaw has both an esoteric manifestation and an exoteric manifestation. The exoteric manifestation is the lack of quantity and rigorous categorization. Exoterically, science is built upon these two things; they consist of counting and making countable respectively.</p>
                <p>Historical materialism and technologism are both very guilty of this flaw, but idealism is infinitely more so. Historical idealism is basically Herodotean; the Ideas are deities and the explanation is that the gods willed it. The other two are merely subscientific, like alchemy or Ptolemyism or Aristotle’s physics.</p>
                <p>I am confident in claiming that historical idealism is by far the worse because I believe that I am the only one who has ever attempted to render its contents scientific with rigorous categorization and quantification. My work in this regard consists of a mathematical model for idea spreading which I published in the form of a Twitter thread, and an unpublished paper outlining three continuous models of memetics (I’ll publish this soon! Do heckin smash that subscribe button to be notified.) The old Dawkins-inspired literature begins and ends with a type IV derivation (a derivation is basically a sub-scientific idea, really a justification for a feeling) per Pareto, an analogy of ideas to genetics.</p>
                <p>Where there is derivation, there is not quantification and rigorous categorization. Hence the exoteric manifestation of the flaw. Pareto’s idea of derivation also points to the esoteric manifestation of the flaw, the underlying motivation for sub-scientific writing, a.k.a pseudo-scientific scholarship. This motivation is, generally, a tendency to place one’s hedonic preferences over the truth. Psychologically I suspect that truth-seeking is basic instinct found in man. Its intensity varies between people, and in people are other desires, such as the desire for money, the desire for status, the desire to have sex, or the desire to dominate. Some fields of knowledge serve these other desires well on the condition that certain things are believed about them. If climate change is false, for instance, the liars may very well be motivated by the desire for money and status, hence their false alarmism, which renders their craft hyper-important, making them high status and the recipients of many grants.</p>
                <p>When a field can serve desires that are not truth, and when its ability to do that depends on or is enhanced by lying, the field will attract lying activists which seek to subvert the field for selfish hedonic pleasure. A real life example of this is <a href="https://cremieux.medium.com/is-eric-turkheimer-a-scientist-ed5850b028d1">Eric Turkheimer</a>, who <a href="https://emilkirkegaard.dk/en/2018/06/why-is-eric-turkheimer-so-unreasonable-about-race-and-intelligence/">said</a>:</p>
                <q id="quote1"><i>If it is ever documented conclusively, the genetic inferiority of a race on a trait as important as intelligence will rank with the atomic bomb as the most destructive scientific discovery in human history. The correct conclusion is to withhold judgment.</i></q>
                <br></br>
                <q id="quote1"><i>It is the hereditarians who are trying to reach a strong and potentially destructive conclusion, and the burden is absolutely on them …</i></q>
                <br></br>
                <q id="quote1"><i>The hereditarians want all the good things that come from being thought of as scientists. They want academic respect, they want protection from charges of racism, they want clear separation from the very recent history of “race science” that led directly to the Holocaust and Jim Crow.</i></q>
                <p>Turkheimer is Jewish and evidently his need for racial dominance is stronger than his drive for truth. Arthur Jensen, who was racially a quarter Jewish through his half Jewish mother, was, on the other hand, a crusader for truth, and a genius, despite the Shoah. He never feared controversy and was very clearly more concerned with truth than with petty hedonism.</p>
                <p>Jensen was always rigorously conceptualizing and quantifying. He produced amazing, seminal work on the quantifiable concept heritability among numerous other things. One need only to read his book <i>Educability and Group Differences</i> to see that the man possesses a highly scientific mind in the exoteric sense. Turkheimer, on the other hand, has at least one major study that has mysteriously failed to replicate (the one claiming poor kids have more environmental variance on IQ) and has made no major theoretic, scope-expanding contributions to his field. Curious!</p>
                <img src={require("./GeneticTheoryImg/1.jpg")} width="600" height="400"></img>
                <p>So we see the symmetry between the exoteric and the esoteric manifestation of the Flaw of sub-scientific thought. As above, so below. Where one finds sub-scientific thought, one finds deficiencies in intelligence and/or honesty.</p>
                <p>And now we are approaching a scientific theory of our own which explains the behavior of historians. We should expect to find among them deficient intelligence and/or truth-seeking. I have already begun to create a test which measures truth-seeking; the work has already been done for me regarding intelligence. Edward Dutton and Richard Lynn have already provided <a href="https://www.religjournal.com/pdf/ijrr10001.pdf">some evidence for this hypothesis</a>: they found in 2014 that history professors have lower IQs than STEM professors are are significantly more likely to be liberal or Democrat. Our burgeoning theory has successfully made its first prediction! Historians are both less intelligent and less honest than those who study more scientific fields.</p>
                <p>The subscientific thinker is a virgin of prediction. This is why his way of thinking is inferior. Let us now enlighten History with scientific thinking, such that we may, if we are successful, begin to predict.</p>
                <h2>The French Revolution and the Gene Pool</h2>
                <p>What caused the French Revolution? We have already seen that the dastardly historian things it to be some mix of economics, idealism, and technological advancement. These ideas could potentially be made scientific, but that is evidently either too hard or too honest for historians.</p>
                <p>Let’s look somewhere else, where difficulty is minimized relative to the honesty needed to make a decent hypothesis. What are liberals dishonest about, and what do I know a lot about….? Hmmmmm, let me think … Ah! The gene pool!</p>
                <p>Obviously liberals would never entertain even a Paretian derivation that suggests that society isn’t genetically equal. Consequently, I hypothesize that society is genetically unequal, and this is an important factor in history. An I know for a fact that people are unequally intelligent, and that the average person is too genetically stupid to be able to run society or make their own political decisions.</p>
                <p>Therefore I hypothesize that society is, at any point in time, is run by people who are in the top X% of intelligence. For now, let X = 1.</p>
                <p>Liberals think the stupid people at least have agency, so let’s hypothesize that they don’t have agency. This makes the mass a bioweapon; the defining feature of a governing elite is that it controls this bioweapon. How exactly they control this bioweapon is unclear, but there seems to be some sort of inertia effect. Maintenance of loyalty ties is relatively easier than acquisition and can be self-perpetuating; the bioweapon is used to enforce loyalty on itself through force and propaganda, including the force and propaganda which gives the governing elite controlled money system legitimacy. Perhaps this renders money to merely be a symbolic representation of ownership of the bioweapon.</p>
                <p>Why would revolution happen, in this model? It follows that if a critical mass of natural elites is barred from having partial ownership over the bioweapon, they may attempt to seize part of it and will then proceed to use it to wrest the remainder from the hands of haughty, closed off governors.</p>
                <p>This might fit the French Revolution, which in essence was a closed off governing elite of nobles getting overthrown by a non-noble elite via the non-governing elite’s use of the bioweapon. The big question is, why did this happen when it did? The historians claim it was a mix of ideas, economics, and technology. Is this to say that there was a capable governing elite outside of the nobility the whole time, which lacked the means and opportunity to seize power? This seems <a href="https://en.wikipedia.org/wiki/Peasants'_Revolt">unrealistic</a>.</p>
                <p>What if there was no elite outside of the nobility that was genetically capable of rebelling against their closed off rule? What if the French Third Estate literally evolved genetically, producing a high IQ elite capable of destroying the nobility? This would explain why the closed-off nobility scheme worked for so long, until finally it was destroyed.</p>
                <p>Notice how much more scientific this theory is compared to the standard historian babble. The historian has no mechanism and consequently their verbal derivations are elementary. At their truisms can be confirmed. Indeed there was a poor economy at the time of the French Revolution. Maybe other revolutions also display a poor economy. What percent involve a poor economy? Certainly not every revolution. Does any historian even try to count this? No, because then they would be on their way to thoughtcrime and scientific thinking. If it were found that 80% of revolutions proceed from a poor economy the next question would be the degree to which a bad economy predicts a revolution. And this might lead one to look for mechanism.</p>
                <p>Only Peter Turchin and his Cliodynamicist colleagues have attempted to quantify these things. <a href="https://en.wikipedia.org/wiki/Elite_overproduction">They have found, according to Wikipedia, the following</a>:</p>
                <q id="quote1"><i>According to Turchin and Jack Goldstone, periods of political instability have throughout human history been due to the purely self-interested behavior of the elite.</i></q>
                <br></br>
                <q id="quote1"><i>Turchin has said that elite overproduction explains social disturbances during the late Roman empire and the French Wars of Religion</i></q>
                <p>Even they have missed the mechanism, however, kosherly claiming an uncritical prole uprising theory.</p>
                <p>Our theory has predicted the finding that elite overproduction, i.e. the accumulation of people of a top 1% IQ outside of the governing elite, is associated with revolution. Let us now see if we can find evidence that the average IQ of the Third Estate had increased prior to the Revolution, the major prediction of our theory.</p>
                <p>There is indeed evidence. <a href="https://thealternativehypothesis.org/index.php/2017/03/15/first-worldism-part-5-the-european-revolution/">Ryan Faulk reports</a> that from 1250 to 1800, “the top 35.4% of the population by wealth or other status indicators of status had 32.9% more offspring than the bottom 65% did within ‘Europe and North America’, according to <a href="https://www.demographic-research.org/volumes/vol18/5/18-5.pdf">a paper by Vegard Skirbekk in 2008</a>.” Meanwhile, the clergy and nobility combined were less than 5% of the French population at the start of the Revolution, according to Wikipedia.</p>
                <p>Sadly I am not aware of any studies estimating the intelligence of the nobility. This could potentially be done using polygenic scores if we have access to noble remains that feature quality DNA samples, which we probably do.</p>
                <p>Let’s check the living nobility, the elite of this society. Are they high IQ? From <a href="https://www.psychologytoday.com/files/attachments/56143/wai-the-global-elite-in-press.pdf">Wai 2014</a>:</p>
                <q id="quote1"><i>Among billionaires and Davos attendees, many majored in business and STEM. In the U.S., top 1% ability individuals were highly overrepresented: 45 times (base rate expectations) among billionaires, 56 times among powerful females, 85 times among powerful males, and 64 times among Davos participants.</i></q>
                <p>Pumpkin Person <a href="https://pumpkinperson.com/2016/02/11/the-incredible-correlation-between-iq-income/">estimates the average IQ</a> of a billionaire to be 133 and the average IQ of a deca-billionaire to be 151. While it would appear that IQ may not be the only thing that determines who will be elite, it’s heavily involved, as our theory predicts. Add another trait or two, like conscientiousness and will-to-power, and maybe the theory would be complete.</p>
                <p>So, there’s our genetic theory of the French Revolution. Is this not a much clearer picture than saying “because the Enlightenment happened?”</p>
                <p>In the spirit of scientific thinking, I’ll leave off with a list of hypotheses to further study. Perhaps this could turn into a real essay of mine: 1. Traits that determine eliteness. Intelligence, conscientiousness, etc. Necessity of these traits, i.e. what activities of the elite require them. 2. The bioweapon and how it is wielded and maintained. Habit, propaganda, money, force? (Note that these correspond to my two main subdivisions of exousiology, Class HBD and hierarchy modeling).</p>
                <div className='author'>
                        <p>Henry Bearcroft, May 24, 2022</p>
                        <p><a href="https://www.notupyet.com">Youtube</a>&ensp;<a href="https://www.notupyet.com">Odysee</a>&ensp;<a href="https://exousiology.substack.com/p/genetic-theory-of-the-french-revolution?s=w">Substack</a></p>
                </div>
                
                <a href={genetic}><p><i>Download PDF</i></p></a>
                
        </div>
        </div>
    )
}