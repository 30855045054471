import React from 'react';
import { deletePost } from '../api/index';
import { useNavigate } from 'react-router-dom';

export default function EditStatic({ page_name }) {
    const admin = localStorage.getItem('admin');
    const nav = useNavigate();
    const editStr = `/edit_static/${page_name}`;

    return (
        <div>
            { admin ? (
                <div>
                    <button className="button edit-button">
                        <a className="plain-href" href={editStr}>Edit Post</a>
                    </button>
                </div>
            ) : (
                <div></div>
            )
            }
        </div>
    );
};