
import "./topbar.css"
import React, { useState, useEffect } from 'react';
import { getPagesApi } from '../api/index';

 function TopBar()
{
    return (
        <div className="top">
            <div className="topCenter">
                Quantitative Sociobiology
            </div>
        </div>
    )
}

 function TopBar2()
{
    const [pages, setPages] = useState([]);
    useEffect(() => {
        const getPages = async () => {
            try {
                const response = await getPagesApi();
                console.log(response.data);
                setPages(response.data);
              } catch (error) {
                console.error('Error:', error);
              }

        };
        getPages();
      }, []);
    const admin = localStorage.getItem('admin');
    return (
        <div className="top2">
            <div className="topCenter2">
                <ul className="topList">
                    <li className="topListItem">
                         <a href="/">RECENT</a>
                    </li>
                    {pages.map((page) => (
                        <li key={page.id} className="topListItem">
                             <a href={page.type === 'static' ? `/static/${page.name.toLowerCase()}` : `/page/${page.name.toLowerCase()}`}>{page.name}</a>
                        </li>
                    ))}
                    { admin === 'true' ? (
                        <li className="topListItem">
                            <a className="plain-href" href="/addpage">+</a>
                        </li>
                        ) : (
                            <div></div>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}

export {TopBar, TopBar2}