import "../css/Recent.css"
import BAP from "../pdfs/bap.pdf";
import ThreeModels from "../pdfs/threemodels.pdf";
import Beta10 from "../pdfs/manuscriptbeta1.0.pdf";
import dysgenicsedit from "../pdfs/dysgenicsedit.pdf";
import youth from "../pdfs/youth.pdf";
import altruism1 from "../pdfs/altruism1.pdf";
import Beta11 from "../pdfs/manuscriptbeta1.1.pdf";
import study1 from "../pdfs/1stPAStudy.pdf";
import memetics1 from "../pdfs/memetics1.pdf"
import taboo from "../pdfs/taboo_paper.pdf"
import Beta12 from "../pdfs/manuscriptbeta1.2.pdf";
import costsOfWoke from "../pdfs/costsOfWoke.pdf";
import michels from "../pdfs/michels.pdf";
//import React, {useEffect, useState} from 'react';
//import axios from "axios";

export default function Recent ()
{

  //  const [listOfPosts, setListOfPosts] = useState([]);
  //      useEffect(() => {
  //          axios.get("http://localhost:3001/posts").then((response) => {
  //              setListOfPosts(response.data);
  //          } );
  //      }, []);
    return (
    <div className="Recent">
        <div className='Writings'>
            <div className='Writings-text'>
             <h1 id='Alpha'>RECENT</h1>
             <div className='LinkAndSummary'>
                    <h1 id='myh1'>Secret Page</h1>
                    <p id='p1'> <a href= {BAP}>BAP</a> and <a href={ThreeModels}>Three Models</a></p>
                    <p id='p1'> <a href= {Beta10}>Manuscript Beta 1.0</a></p>
                    <p id='p1'> <a href= {dysgenicsedit}>Dysgenics edit</a></p>
                    <p id='p1'> <a href= {youth}>Youth book</a></p>
                    <p id='p1'> <a href= {altruism1}>Dictator game and ultimatum game</a></p>
                    <p id='p1'> <a href= {Beta11}>Manuscript Beta 1.1</a></p>
                    <p id='p1'> <a href= {study1}>1st Political Agency Study</a></p>
                    <p id='p1'> <a href= {memetics1}>Mind virus study</a></p>
                    <p id='p1'> <a href= {taboo}>Tabooness slides</a></p>
                    <p id='p1'> <a href= {Beta12}>Manuscript Beta 1.2</a></p>
                    <p id='p1'> <a href= {costsOfWoke}>Costs of Woke</a></p>
                    <p id='p1'> <a href= {michels}>Michels</a></p>
                    <br></br>
                </div>
            </div>
        </div>
    </div>
    )
}

              
    //            {listOfPosts.map((value, key) => {return ( 
    //            <div className='LinkAndSummary'>    
     //               <h1 id='myh1'>{value.title}</h1>
     //               <p id='p1'>{value.postText}</p>
     //               <br></br>
     //           </div>)})}

