import "../css/Michels.css"
import React, { useState } from 'react';

export default function Game ()
{
    const [count, setCount] = useState(0);
    const [rv, setRv] = useState(0);
    const incrementCount = () => {
        setCount(count + 1);
      };
      const generateBinaryRandomVariable = () => {
        return Math.random() < 0.5 ? 0 : 1;
      };
    return (
        <div className='Michels'>
            <div className='Michels-text'>
                <p>Count: {count}</p>
                <p>RV: {rv}</p>
                <button onClick={ () => {
                                    setRv(generateBinaryRandomVariable());
                                    }}>Generate</button>
                <button onClick={ () => {
                                    if (rv == 0) incrementCount();
                                    }}>Button 1</button>
                <button onClick={ () => {
                                    if (rv == 1) incrementCount();
                                    }}>Button 2</button>
            </div>
        </div>
    )
}
