import "../../css/Michels.css"
import michels from "../../pdfs/PoliticalAgency.pdf";
var Latex = require("react-latex")

export default function Mosca ()
{
    return (
        <div className='Michels'>
            <div className='Michels-text'>
            <h1 id='essayTitle'>Estimating the Number of People with Political Agency</h1>
            <h2>Introduction</h2>
            <p>Robert Michels, in his famous text Political Parties, gave five main reasons for the Iron Law of Oligarchy: the physical limits of communications, the necessity of oligarchism for the martial fitness of an organization, the necessity of professional management, the ambitious and Machiavellian tendencies of leaders, and the apathy of the masses. While his book was a great work for its time, it falls short of modern scientific standards. Luckily, we have over 100 years worth of social science data and psychometric theory which can serve to scientifically verify, or falsify, Michels’ hypotheses. This essay will focus on the apathy of the masses.</p>
            <h2>Intelligence</h2>
            <p>IQ is the most well-studied psychometric in existence, and consequently we should milk it as much as possible. The fact is that most people are very stupid, too stupid, in fact, to conceivably have political agency. To see why this is true we first must define political agency. What we are interested in is the following: what is the set of people whose phenotypes matter to history? In other words, who do we have to track in order to predict social change? If everyone had political agency, the answer would be the whole population. If very few people have agency, then “democracy” is really about the people with agency fighting for control over the people without agency. And if some of these high agency people are clearly more capable than others, we can just look at them to be able to predict what will happen in a society. In other words, we will have found our ruling class, or governing elite.</p>
            <p>Political agency, then, can simply be defined as the extent to which a person thinks for themselves on political matters. If they outsource their thinking, either because they aren’t capable of doing it, don’t have the time, don’t want to, or aren’t willing risk the status of doing so (this last effect would mostly arise from the first few effects allowing there to be a pre-existing consensus which status is assigned to), then they become an object of control, and it follows pretty easily from some basic assumptions that elites will successfully seize that object the vast majority of the time, rather than high agency proles engaging in un-backed grassroots activism.</p>
            <p>It follows that intelligence is relevant to political agency in that it may allow us to prove that some portion of the population is necessarily too stupid to think for themselves on political matters. Consider HBD. Most people seem to be HBD denialists. Is this something that people thought up themselves, or were they told to believe this? We can begin to estimate the number of people who believe what they’re told on this subject by estimating the number of people who even have the minimum intelligence required to understand HBD research. For many high IQ people, it’s surprising to find out how profoundly stupid the average person is; I recall that in high school I used to project my own, highly atypical mind onto others, and consequently I was a libertarian. Slowly I started learning about the science of intelligence, the basics of probability distributions, and that intelligence is normally distributed and that I was easily at the 99.9th percentile for the trait per testing, and that this is basically all due to genes and not choice or some other malleable property. Eventually I told my friend these things; he was also a high IQ libertarian. We were in an almost de-facto segregated, difficult program, and he refused to believe me. He explicitly said he believed that everyone is about as intelligent as everyone else, for all intents and purposes. Highly intelligent people believe this, but it’s not the case.</p>
            <img src={require("./PoliticalAgencyImg/1.png")} width="500" height="250"></img>
            <p>Above is a chart displaying the findings reported in Jayaratne et al. (2006) [1]. We can do some simple reasoning: the correct answer is “a lot” or “just about all.” Without a doubt, 74% of respondents were wrong and therefore don’t have political agency (when it comes to HBD — we will see later if this generalizes to other topics, whether they be contemporary or historical). It’s reasonable to also deny agency to the “some group” , meaning 94% of people lack political agency, but need this be done in the context of the Iron Law of Oligarchy? Is not a crushing, super-duper majority of 74% of people enough to essentially make history the interplay of elites who capture portions of the low agency mass to use against rivals? Would not the 1 in 4, insofar as they have agency yet lack elite talents, be totally subordinated to the very few who are outstandingly intelligent, conscientious, and ambitious, who build their power over the 3 in 4 while the 1 in 4 must spend their days working to get by?</p>
            <p>It would be enough. The 1 in 4 are conservatives and the 3 in 4 are liberals. It maps onto reality well — only about 45% of the overall population actually vote, and Democrats are always the ones trying to increase turnout. We see how that works out for the 1 in 4: the liberal elite dominate everyone and everything more or less.</p>
            <img src={require("./PoliticalAgencyImg/2.png")} width="500" height="300"></img>
            <p>It’s hard to draw an exact border between “has agency” and “does not have agency.” Like IQ, “agency” can be more appropriately conceptualized as a continuous variable. It is interesting that Jayaratne’s results form roughly a Gaussian where the whole left side is thrown into the “no genetic effect” box and where the other boxes more cleanly capture the right side. You can see that you might draw the meaningful agency line somewhere in between .5 SDs and 1.8 SDs.</p>
            <p>Let’s try to get a clearer picture by examining why so many people seem to lack agency. First, how many people are actually intelligent enough to have an informed view on race and genetics? To understand this topic, a person must be able to understand basic statistics like linear regression.</p>
            <p>One major statistics test given yearly is the AP Statistics test.</p>
            <img src={require("./PoliticalAgencyImg/3.png")} width="500" height="400"></img>
            <p>From Collegeboard’s website, 42% of test takers fail the exam by scoring below a 3. This is very sad considering it is extremely easy. So by naively accepting College Board’s standards and by ignoring the fact that the average IQ of a test taker is probably above 100, we can estimate that at least 42% of people are incapable of forming a basic independent understanding of race and genetics. This is almost certainly an underestimate, however. From a 2012 practice test, we can see that by normal academic standards an F becomes a 3 for College Board.</p>
            <img src={require("./PoliticalAgencyImg/4.png")} width="500" height="400"></img>
            <p>A reasonable thing to do here would be to just declare half of the 3s failures, and this would be charitable at any rate. That tells us that 53% of the population can’t understand race and genetics, they have to be told what to think even if they had the time and desire to look at research.</p>
            <p>But the 53% figure assumes the average AP Statistics test taker has an IQ of 100. In reality they should have a higher IQ than that. <a href="https://pumpkinperson.com/2017/01/17/iq-academic-success/">Pumpkin Person reports that the average IQ of a US college graduate is 108.</a> It’s reasonable to take this as a charitable estimation of the average AP Statistics test taker IQ since AP tests are generally only taken by college bound students. In reality the average might be even higher since quantitative AP tests skew toward an even higher IQ, mirroring quantitative majors. Many of the lower IQ college students choose majors which allow them to avoid calculus and statistics.</p>
            <img src={require("./PoliticalAgencyImg/5.png")} width="500" height="400"></img>
            <p>Above is data presented by <a href="http://www.randalolson.com/2014/06/25/average-iq-of-students-by-college-major-and-gender-ratio/">Randy Olson</a> showing that it could be fair to estimate the average AP statistics test taker to have an IQ as high as 124. Despite this, let’s charitably stick with 108 and see what we get. The 53rd percentile IQ of a normal distribution with a mean of 108 and a standard deviation of 15 is 109. That’s 0.6 SD in a distribution with a mean of 100 and an SD of 15, or 72nd percentile. This means that 72% of the population basically can’t understand race and genetics at all.</p>
            <p>If instead we assumed the average AP statistics test taker has an IQ of 124, we discover that 95% of the population basically can’t actually understand HBD. We are getting strangely convergent numbers — somewhere between 72% and 95% of the population is too stupid to have meaningful political agency. 72% is the charitable estimate and 95% is the rationally defendable, low charity estimate.</p>
            <p>Before moving on from IQ for now, let’s look a few more things. Some of these will focus on verbal intelligence instead of mathematical. Whereas you can’t really form hypotheses or understand the state of knowledge of most political questions today without knowing what linear regression is (immigration is race HBD, feminism is sex HBD, education is age HBD, democracy is this, class HBD, economics is economics, etc [one interesting thing to note is conservatives/dissidents seem to have more “power” when it comes to mostly “moral” things like abortion, gun control, etc, but even there an informed opinion only comes from statistical understanding]) in the past political agency was more verbal. You are probably not coming up with your own new political ideas in 1750 if you can’t at least comprehend Plato, Aristotle, the Bible, Aquinas, St. Augustine, etc in theory. If you cannot understand these people, you aren’t going to understand new ideas from e.g. Voltaire either, anymore than you will understand this post if you can’t pass AP Statistics. So, given that genetic IQ has only increased since antiquity, finding extreme deficits in reading comprehension for most people will demonstrate that they wouldn’t have been any more capable of intellectual political agency 1000 years ago.</p>
            <p>It turns out most people are functionally illiterate. <a href="https://archive.ph/o/NZicZ/https://nces.ed.gov/naal/kf_demographics.asp">The NCES estimates that,</a> as of 2003, only 57% of adults are able to analyze a passage of prose.</p>
            <img src={require("./PoliticalAgencyImg/6.png")} width="500" height="300"></img>
            <p>The NCES classifies literacy into 4 categories: 1) Below Basic - This runs the gamut from "doesn't know the alphabet" to "can read some words", and represents 14% of American adults. 2) Basic - This means someone capable of reading signs and labels to the point they can function in everyday life, and understand enough of small texts to extract basic ideas and information. 29% fall into this category. 3) Intermediate - This means someone who is able to digest basic prose and draw conclusions from, summarize, and otherwise meaningfully analyze the contents. 44% fall into this category. 4) Proficient - This is someone who can do all the things the Intermediate level can, but while analyzing more dense and complex texts. 13% fall into this category.</p>
            <p>This means 43 - 87% of the population is functionally illiterate. Split the difference on the iffy Intermediate category and that’s 65% of the population.</p>
            <p>Let’s look inside a test like this. <a href="https://archive.ph/MGAja">Robin Hanson gives us the following</a>:</p>
            <q id="quote1"><i>A common bias among the smart is to overestimate how smart everyone else is.  This was certainly my experience in moving from top rank universities as a student to a mid rank university as a teacher.  A better intuition for common abilities can be found by browsing the US National Assesment of Adult Literacy sample questions.</i></q>
            <br></br>
            <q id="quote1"><i>For example, in 1992 out of a random sample of US adults, 7\% could not do item SCOR300, which is to find the expiration date on a driver’s license.  26\% could not do item AB60303, which is to check the “Please Call” box on a phone message slip when they’ve been told: “James Davidson phones and asks to speak with Ann Jones, who is at a meeting. He needs to know if the contracts he sent are satisfactory and requests that she call before 2:00 p.m. His number is 259-3860. Fill in the message slip below.”</i></q>
            <br></br>
            <q id="quote1"><i>Only 52\% could do item AB30901, which is to look at a table on page 118 of the 1980 World Almanac and answer: “According to the chart, did U.S. exports of oil (petroleum) increase or decrease between 1976 and 1978?”</i></q>
            <br></br>
            <q id="quote1"><i>Only 16\% could do item N010301, which is to answer “What is the purpose of the Se Habla Espanol expo?” after reading a short newspaper article called “Se Habla Espanol Hits Chicago; September 25,26,27 are three days that will change your marketing.” The article includes this quote: “It’s Mr. Martinez’s job—his mission in life—to make sure companies learn how they can serve and sell to America’s Hispanics. He has been marketing to the community for many years, working with the best in the business, including Coca-Cola and the advertising firm of Castor GS\&B. Now his staff is organizing the largest annual Hispanic market trade show in the business—Se Habla Español.”</i></q>
            <br></br>
            <q id="quote1"><i>Acceptable answers include statement such as: “To enable people to better serve and sell to the Hispanic community; to improve marketing strategies to the Hispanic community; and to enable people to establish contacts to serve the Hispanic community.”</i></q>
            <br></br>
            <q id="quote1"><i>Only 11\% could do Item N100701, which asks: “Using the information in the table, write a brief paragraph summarizing the extent to which parents and teachers agreed or disagreed on the statements about issues pertaining to parental involvement at their school.”</i></q>
            <img src={require("./PoliticalAgencyImg/7.png")} width="500" height="500"></img>
            <p>Something like 84 to 89% of people are totally functionally illiterate according to this test.</p>
            <p>Another common reading assessment is the reading SAT. The overall average for the verbal section is a 530/800. This implies that the average for the reading section is about a 26.5/40, maybe lower because the reading is generally harder than the grammar for people. This score requires missing about half of the questions on the test according to the curve from the first SAT (version 3) practice test. <a href="https://satsuite.collegeboard.org/media/pdf/understanding-sat-scores.pdf">College Board provides percentiles for the overall verbal test</a>:</p>
            <img src={require("./PoliticalAgencyImg/8.png")} width="500" height="500"></img>
            <p>We can estimate what percentile a certain reading score is by just assuming it’s the whole verbal test (the sections are very similar and correlate well anyway). From my own experience I would struggle to consider someone literate if they missed more than 20% of the questions. The test is very easy and requires hardly any specific outside knowledge, unlike the math section. See for yourself <a href="https://satsuite.collegeboard.org/media/pdf/sat-practice-test-1.pdf">here</a>. Getting 80% of the questions right would translate to a 680 which is 95th percentile. I didn’t plan that outcome but yet that number has popped up again. Curious!</p>
            <p>To finish off our look at intelligence we will quote <a href="https://archive.ph/vxN69">Anatoly Karlin</a>.</p>
            <img src={require("./PoliticalAgencyImg/9.png")} width="500" height="450"></img>
            <p>Only 13% of OECD citizens could answer this basic question correctly, aka 87% failed it.</p>
            <img src={require("./PoliticalAgencyImg/10.png")} width="500" height="450"></img>
            <p>The vast, vast majority of people, 75-95% of white people, are too stupid to actually understand what they’re talking about when they say race isn’t real, or genetics don’t matter. These people can’t read, they can’t understand a linear regression, they are surely not coming up with these ideas themselves. Rather, they are repeating others. They do not have the capacity to seriously consider the Divine Right of Kings and whether or not Popular Sovereignty trumps it. When they are told these things they are going to understand them at about the level of a 145 IQ person at the age of 11 or 12. The same goes for all other views — the evidence indicates that the average person, intellectually speaking, is about as smart as I was at 12. This gives me, and should probably give you, a good, intuitive model of what most people are like. At 12 you repeat the adults. 12 year olds are obedient. When you are 12, if the food is good then life is good. When you are 12, you throw a tantrum because you didn’t get some material good you wanted — likewise basically all true spontaneous protests are economically motivated and boil down to proles wanting more stuff (more on this at another time).</p>
            <p>More continuously, we could say that the bottom 75% think like me, and hopefully you, at 11 or under. The next 10% are like us at 12, the next 10% at 13 or so, and then finally we reach people with some serious amount of intellectual political agency, although 95th percentile is certainly not the ceiling. This is simply where people begin to show mere reasonable capacity to think for themselves in a sufficiently complex, adult sort of way.</p>
            <p>We should end by this section by predicting that if intelligence is so important for political agency, then it follows that the ruling elite will be highly intelligent, almost all beyond the 95th percentile, if capacity for political agency (i.e. the ability to come up with new policies, persuade people to accept them, to direct people with a purpose of your own such that you capitalize bigly) is important for being a ruling elite. <a href="https://www.psychologytoday.com/files/attachments/56143/wai-the-global-elite-in-press.pdf">This is in fact the case</a>.</p>
            <h2>Temperament</h2>
            <p>Low IQ is a hard barrier to political agency, but how many high IQ people “choose” to express political agency? There are several considerations here, some environmental and some genetic. Some people naturally don’t care for politics. Who among you have not noticed the mathematician’s haughty and embarrassing political ignorance, his tendency to use his sharp mind to solve abstract theorems in a day and age which calls action from anyone who can give it? There are many such people, perhaps the majority of the high IQ are like this mathematician we have thought up, living their whole lives just going with the flow, never shedding their ignorance of the science and intricacies of the most important questions of the day, blindly trusting the talking head as much as the factory worker, if not more due to status considerations. Such people cannot be said to have political agency.</p>
            <p>Others don’t have the time or the opportunity but perhaps aren’t naturally opposed to the idea of expressing agency under the right conditions. They simply care more for money, or video games, or sex, and so on. Most people in the 95th percentile of intelligence still have to wage slave for 40 hours a week. Between that and all the distractions of daily life they choose to indulge in, when do they have the time to read Arthur Jensen? Whereas the mathematician could never be made to exercise political agency, this second type will if his other desires are saturated. Truth is <a href="https://en.wikipedia.org/wiki/Indifference_curve">merely another good on an indifference curve</a> for such a person. I know of at least one Bitcoin millionaire who, after spending all of his time before making his small fortune focusing on money acquisition, has now settled into <a href="https://minordissent.substack.com/">political thought.</a> Curtis Yarvin may be another example of this type; it is unclear if he did much of anything political in his youth but a few years after retiring on his dot com bubble profits he was writing UR. I have talked to countless intelligent people who linger around dissident blogs, never quite going beyond a certain subtle level of ignorance, which when questioned will admit their lack of time. They are only halfway exercising their agency because of their relative indifference to the consumption of the good Truth.</p>
            <p>Whereas these types are constructed as lacking intrinsic appreciation for political agency, still others may lack certain moral virtues which its full pursuit requires — bravery, honesty, confidence, diligence, integrity, altruism, conscientiousness, and so on. Thinking outside of the box is dangerous work. Not only may intelligent people derive limited intrinsic pleasure from the act, they may also be positively scared by it. They may not see it as useful to them even if they find enjoyment in it. The truth may be a danger to their position in society and consequently they may lie about it. They may even be too lazy to seriously pursue it.</p>
            <p>Let’s examine the distribution of these traits. We will first begin with moral reasoning. Kolhberg infamously found that 85% of adults exhibit “conventional” morality. This means that they lack moral agency, which as far as I can see is a necessary precondition to political agency. In other words, for 85% of adults, the secular law is what is moral. This means that they will be incredulous towards any new policy unless the need for it is demonstrably shown by someone who they feel is morally authoritative. These people, this vast majority, are temperamentally followers.</p>
            <p>Only a few are “post conventional” are exhibit moral reasoning based on eternal principles, meaning only a few have the capacity to say “that law is morally wrong and it must be repealed.” The question is, what percent of our cognitive political agents exhibit conventional moral reasoning?</p>
            <p>Derryberry et al. (2005) [2] found that, among college students, conventional morality was predominant and post-conventional morality correlated with ACT scores are r = .335. It follows that a smaller, yet sizeable amount of intelligent people will exhibit conventional morality. Extrapolating from this data we would expect people with a 98th percentile intelligence to still be slightly predominant in conventional morality, on average, although this assumes that there is not a negative correlation between IQ and conventional reasoning.</p>
            <p>The study also examined “gifted youth” who were 12 to 16 (14.39 +/1 1.14 years old on average) who needed average scores on the ACT or SAT to be considered “gifted.” This means they were merely representative of the top 50%, plus a little for being on the younger side. Post-conventional reasoning predominates in about half of them but the correlation with test scores is weaker. From this study alone, 40-50% of 95th percentile IQ people probably have predominate conventional moral reasoning.</p>
            <img src={require("./PoliticalAgencyImg/11.png")} width="500" height="400"></img>
            <img src={require("./PoliticalAgencyImg/12.png")} width="500" height="400"></img>
            <p>Another study on high schoolers [3] with ~75th percentile SAT scores on average found really weak correlations between SAT score and post-conventional reasoning. Conventional reasoning actually yielded higher a higher correlation in this study.</p>
            <img src={require("./PoliticalAgencyImg/13.png")} width="500" height="400"></img>
            <p>One interesting study [4] actually looked at the moral reasoning of CEOs by examining their public statements and found 8 out of 10 exhibited sub-post-conventional reasoning. CEOs of course are going to almost always cognitively elite, the study linked above in this connections found, for instance that over 38% of Fortune 500 CEOs went to elite colleges that require top 1% SAT scores.</p>
            <img src={require("./PoliticalAgencyImg/14.png")} width="500" height="400"></img>
            <p>One study actually looked at highly intelligent young adults [5]. It was found that many of those above the 95th percentile, even a majority, were primarily conventional thinkers, like the average. Furthermore a correlation between post conventional moral reasoning and cognitive ability was found to be 0.30 similar to the previous study.</p>
            <img src={require("./PoliticalAgencyImg/15.png")} width="500" height="350"></img>
            <p>Above, P-score is post conventional reasoning, and scholastic rank is actually the student’s percentile on a 7th or 8th grade placement test.</p>
            <p>It is reasonable, given this data, to claim that about half of those with an intelligence beyond the 95th percentile will reason conventionally, and consequently will be too obedient to exercise political agency. We may therefore expect that 2 or 3% of people will ultimately express political agency, based on what we have seen so far.</p>
            <p>I have only one trick left for this excursion: attempting to measure, in abstract, how many people actually engage in serious, independent political thought.</p>
            <p>For now I will cite only one thing, the average number of books read per year. Serious independent thought requires going beyond memorizing facts you don’t understand — it requires learning the theory behind things. This can only be done by reading books, journal articles, and writings like these. I assume that most people don’t do the latter two, so how many non-fiction books do people read?</p>
            <img src={require("./PoliticalAgencyImg/16.png")} width="500" height="350"></img>
            <p>According to <a href="https://news.gallup.com/poll/388541/americans-reading-fewer-books-past.aspx">Gallup</a> that average American reads only 12 books per year. This distribution is skewed, however. Only 27% read more than 11 books per year. It’s fair to say that bottom 73% are not going to be informed about much of anything with that low amount of reading. Frustratingly there was no data on nonfiction books in particular but one study [6] estimated by magazine subscriptions that it was 1% of the population in 1998. We’ll charitably assume 40% of the books read according to the Gallup poll are nonfiction; this is the sales ratio given by a 2018  [7]. This means the average American reads only 4 or 5 nonfiction books per year. The median probably reads less than 2. Hardly any are reading at least one nonfiction book per month — charitably we might assume people read either nonfiction or fiction, meaning 10% of the population reads 11 or more nonfiction books per year.</p>
            <p>And what do they read? Is it rigorous non-fiction, like <i>HBD At Lightspeed</i>, <i>An Empirical Introduction to Youth</i>, <i>The Mind and Society</i>, or <i>Exousiology: The Scientific Theory of Power</i>? Of course not, they read absolute garbage.</p>
            <p>Current Amazon nonfiction bestsellers:</p>
            <ol>
            <li><p>Ignite a Shift: Engaging Minds, Guiding Emotions and Driving Behavior</p></li>
            <li><p>DSM 5</p></li>
            <li><p>BEQOMING: Everything You Didn't Know You Wanted (lol)</p></li>
            <li><p>Pati's Mexican Table: The Secrets of Real Mexican Home Cooking</p></li>
            <li><p>Tiny Hliabits: The Small Changes That Change Everything</p></li>
            <li><p>Lenin's Tomb: The Last Days of the Soviet Empire (this is basically a novel, a bunch of anecdotes with horrible exuberant prose)</p></li>
            <li><p>If You Tell: A True Story of Murder, Family Secrets, and the Unbreakable Bond of Sisterhood</p></li>
            <li><p>Dreaming of Flight: A Novel</p></li>
            <li><p>The Greatest Survival Stories of All Time: True Tales of People Cheating Death When Trapped in a Cave, Adrift at Sea, Lost in the Forest, Stranded on a Mountaintop, and More</p></li>
            <li><p>Villains, Scoundrels, and Rogues: Incredible True Tales of Mischief and Mayhem</p></li>
            <li><p>The Quest for a Moral Compass: A Global History of Ethics (actually decent looking! surprising)</p></li>
            <li><p>The Biology of Belief 10th Anniversary Edition (decent book above countered by literal misinformation — I can already imagine the proles uncritically repeating the blurb — “In this greatly expanded edition, Lipton, a former medical school professor and research scientist, explores his own experiments and those of other leading-edge scientists that have unraveled in ever greater detail how truly connected the mind, body, and spirit are. It is now widely recognized that genes and DNA do not control our biology. Instead, they are controlled by signals from outside the cell, including energetic messages emanating from our thoughts.” — and them staring blankly as I try to explain that, yes, medical degrees and bestseller status don’t always mean trustworthy).</p></li>
            <li><p>Embers of Childhood: Growing Up a Whitney</p></li>
            <li><p>Reasons to Stay Alive (lol shitlibs be like)</p></li>
            <li><p>The Community: A Memoir</p></li>
            <li><p>Whiskey Lies (Falling For Whiskey Book 1)</p></li>
            <li><p>Gravity: A Billionaire Romance (The Wilde Boys Book 1)</p></li>
            <li><p>Here's the Deal: A Memoir</p></li>
            <li><p>In Love: A Memoir of Love and Loss</p></li>
            <li><p>Atomic Habits: An Easy & Proven Way to Build Good Habits & Break Bad Ones (literally the same thing as number 5???)</p></li>
            </ol>
            <p>I could go on. It continues like that, forever I assume. I can say with absolute certainty that not a single book on this list is worth reading save for maybe number 11 which actually appeared to be a somewhat decent summary of the history of ethical philosophy written by a somewhat serious author. The rest of the books are just worthless, no-info smutt except for the gene denialist spirit wackjob book at number 12. So a top 10% reader will read 12 books from this list or something like it in a year. If they are lucky they will learn something about ethics which they may or may not understand or have the capacity to finish.</p>
            <img src={require("./PoliticalAgencyImg/17.png")} width="500" height="500"></img>
            <img src={require("./PoliticalAgencyImg/18.png")} width="500" height="500"></img>
            <p>Is this so boring? It is no drier than any of the serious books I have read this year. The prole whines that the book does not cater to its twitterfied attention span!</p>
            <p>Is this list not hilarious and pathetic? Less than 1% of people do any serious reading.</p>
            <h2>Conclusion</h2>
            <p>Democracy is fake; the Iron Law of Oligarchy’s fifth tenet is verified. Only 1-5% of people have political agency. The rest are too stupid to understand basic math or two have basic reading comprehension skills. Of the tiny intelligent fraction, perhaps a majority cannot morally reason beyond the current law of the State and by extension the norms of the day. And finally the top 10% of readers, those who read just one non-fiction book per month, are reading absolute trash, wasting their precious intellectual time on “Tiny Habits” and other garbage in the same vein. How many people of both intelligence and moral capacity are actually reading Arthur Jensen, Elite Theory, or Smart and SeXy? This is maybe 2.5% of the population. It would be generous to say that a quarter reads at this level. Many probably don’t read at all. Of those who do, I have seen far too many reading lists of those who may have agency be filled with New York Times bestsellers and books on random soyboy topics that have nothing to do with politics, like Chinese history or AI. These people will then talk about how they just aren’t sure about what the black-white IQ gap is, but it doesn’t matter anyway because Nick Bostrom says the superintelligence is coming on the clouds any day now to wipe away their tears! Or turn them into paperclips. So they go along with the rigmarole, a rare member of the 2.5% who is ripe for practicing political agency totally squandering their precious gift, forever ignorant of the actual goings on of society, a true idiot by the <a href="https://talesoftimesforgotten.com/2016/11/07/the-bizarre-origins-of-the-word-idiot/">Aristotelian definition of the word</a>, one who does not take part in public affairs.</p>
            <p>[1] Jayaratne, T. E., Ybarra, O., Sheldon, J. P., Brown, T. N., Feldbaum, M., Pfeffer, C. A., & Petty, E. M. (2006). White Americans' genetic lay theories of race differences and sexual orientation: Their relationship with prejudice toward Blacks, and gay men and lesbians. Group Processes & Intergroup Relations, 9(1), 77-94</p>
            <p>[2] Derryberry, W. P., Wilson, T., Snyder, H., Norman, T., & Barger, B. (2005). Moral judgment developmental differences between gifted youth and college students. Journal of Secondary Gifted Education, 17(1), 6-19.</p>
            <p>[3] Lee, S. Y., & Olszewski-Kubilius, P. (2006). The emotional intelligence, moral judgment, and leadership of academically gifted adolescents. Journal for the Education of the Gifted, 30(1), 29-67.</p>
            <p>[4] Weber, J. (2010). Assessing the “tone at the top”: The moral reasoning of CEOs in the automobile industry. Journal of Business Ethics, 92(2), 167-182.</p>
            <p>[5] Narváez, D. (1993). High achieving students and moral judgment. Journal for the Education of the Gifted, 16(3), 268-279.</p>
            <p>[6] Luey, B. (1998). Who reads nonfiction?. Publishing research quarterly, 14(1), 21-35.</p>
            <p>[7] Yucesoy, B., Wang, X., Huang, J., & Barabási, A. L. (2018). Success in books: a big data approach to bestsellers. EPJ Data Science, 7, 1-25.</p>
            <div className='author'>
                    <p>Henry Bearcroft, June 27, 2022</p>
            </div>
            <a href={michels}><p><i>Download PDF</i></p></a>
            </div>
        </div>
    )
}