import React from 'react';
import '../css/Pay.css';

const Pay = () => {
    return (
      <div className="pay-container">
        <div className="pay-content">
          <h1 className="pay-title">Payment Gateway</h1>
          <p className="pay-description">This post is paywalled. To access it and support this content, please become a paid subscriber.</p>
          <button className="pay-button">Proceed to Pay</button>
        </div>
      </div>
    );
  };
  

export default Pay;
