import "../css/Michels.css"

export default function About ()
{
    return (
        <div className='Michels'>
        <div className='Michels-text'>
            <h2>What is this site?</h2>
            <p>This is my archive site. The main place I share my writing is Substack, because of its superior features and network effects. This site will be the home for my writings if I am banned from Substack due to wrongthink. It currently exists on the internet mainly so I can serve up PDFs without using a url like docdroid, which is marked as spam on many platforms. If you found this site, congratulations, but check out my Substack <a href="https://josephbronski.substack.com">here</a> and subscribe if you like my writings. This archive will always lag behind the Substack as long as that is my main home.</p>
            <h2>Types of Writings</h2>
            <p>At the top you can see links to three types of writings: "writings," "book reviews," and "blog posts." Blog posts are mirrored on substack. These posts are shorter, more creative writings -- basically fiction, grand theories, the sort of stuff that the average person really laps up, as opposed to more intellectually ascetic, rigorous scholarship, which is what I put under the writings tab. Book reviews are book reviews.</p>
            <h2>Who Am I?</h2>
            <p>I am Joseph Bronski. I enjoy donating my spare time and energies to research and writing on behalf of the truth. My main project right now is quantitative sociobiology, the scientific theory of human society. I am also the author of <a href="https://www.amazon.com/Empirical-Introduction-Youth-Joseph-Bronski/dp/B095TDQ5FC">An Empirical Introduction to Youth.</a></p>
            
        </div>
        </div>
    )
}