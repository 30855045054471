import "../css/Michels.css"

export default function Videos ()
{
    /*
    return (
        <div>
            <div className='Writings'>
            <div className='Writings-text'>
                <h1 id='Alpha'>ABOUT THIS PAGE</h1>
                <div className='LinkAndSummary'>
                    <p id='p1'>This page consists of indices of my videos. At any given time, only a subset of my writings will have a corresponding video. At the same time, I create videos which have no corresponding writing. Consequently, this page has three sections: Writings, Book Reviews, and Other Videos. The first section contains indices with links to the text and video for every writing for which there is a corresponding video. The second is the same but for my book reviews. The third contains links to standalone videos, along with a description. Each section is in alphabetical order. The reasoning for this scheme is that 1) this website is the central hub for my work 2) I would like to give followers an easy way to discriminate between the types of videos. I am trying to balance my commitment to quality work with my duty to inform -- this duty can tempt one to privilege quantity over quality. My writings are my top tier of work, so I'd like to seperate the videos I have made for them from the videos I make on current events and other things. </p>
                    <br></br>
                </div>
                <h1 id='Alpha'>WRITINGS</h1>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/Centralization">Centralization and Coordination</a></h1>
                    <p id='p1'>How centralized is society and how coordinated are the elite?</p>
                    <p id='p2'><a href="https://www.youtube.com/watch?v=XM-W99LxjWo">Youtube</a>&ensp;<a href="https://odysee.com/@HenryBearcroft:a/centralizationpt1">Odysee</a></p>
                    <br></br>
                </div>
                <h1 id='Alpha'>BOOK REVIEWS</h1>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/MichelsReview">Political Parties by Robert Michels</a></h1>
                    <p id='p1'>A text written 1911 on the Iron Law of Oligarchy.</p>
                    <p id='p2'><a href="https://www.youtube.com/watch?v=DNypn1GzNwg">Youtube</a>&ensp;<a href="https://odysee.com/@HenryBearcroft:a/MichelsReview">Odysee</a></p>
                    <br></br>
                </div>
                <h1 id='Alpha'>STANDALONE VIDEOS</h1>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'>Elon Musk's Attempted Twitter Takeover & The Iron Law of Oligarchy</h1>
                    <p id='p1'>What does Twitter's response say about woke capital? Did Michels predict this?</p>
                    <p id='p2'><a href="https://www.youtube.com/watch?v=reQfT2j7D3I">Youtube</a>&ensp;<a href="https://odysee.com/@HenryBearcroft:a/musk1:4">Odysee</a></p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'>Laughing at Moldbug's take on the Buffalo shooting</h1>
                    <p id='p1'>Moldbug claims that the Nazis did the Holocaust because they wanted to be the bad guy in the liberal narrative.</p>
                    <p id='p2'><a href="https://www.youtube.com/watch?v=U8UYgYfNowM">Youtube</a>&ensp;<a href="http://notupyet.com">Odysee</a></p>
                    <br></br>
                </div>
            </div>
        </div>
        </div>
    )*/
    return (
        <p>Down for maintenance!</p>
       )
}

