import "../css/Michels.css"
import MoldbugPt3 from "../pdfs/MoldbugPt3.pdf";

export default function Writings ()
{
    /*
    return (
        <div className='Writings'>
            <div className='Writings-text'>
                <h1 id='Alpha'>ALPHABETICAL</h1>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/Centralization">Centralization and Coordination</a></h1>
                    <p id='p1'>How centralized is society and how coordinated are the elite?</p>
                    <p id='p2'><a href="https://www.youtube.com/watch?v=XM-W99LxjWo">Youtube</a>&ensp;<a href="https://odysee.com/@HenryBearcroft:a/centralizationpt1">Odysee</a></p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/PoliticalAgency">Estimating the Number of People with Political Agency</a></h1>
                    <p id='p1'>Examining population statistics to verify the 5th pillar of the Iron Law of Oligarchy.</p>
                    <p id="p2"><a href="https://www.notupyet.com">Youtube</a>&ensp;<a href="https://www.notupyet.com">Odysee</a>&ensp;<a href="https://exousiology.substack.com/p/estimating-the-number-of-people-with">Substack</a></p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/Dysgenics">Female Romantic Preferences are Dysgenic</a></h1>
                    <p id='p1'>What women want is not what society needs.</p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href={MoldbugPt3}>Moldbug Summary Part 3</a></h1>
                    <p id='p1'>Summarizes Unqualified Reservations from October 2007 - August 2008. Reviews the Open Letter to Open Minded Progressives and posts leading up to it. Topics include the origin, structure, and function of the Cathedral, plans for reformalization, and analysis of democracy.</p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/Hypotheses">Some Useful Hypotheses for Historical Analysis</a></h1>
                    <p id='p1'>Outlining some hypotheses that can direct the progression of exousiology.</p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/PowerSingularity">The Power Singularity</a></h1>
                    <p id='p1'>A speculative look at a future where leftist elites stomp on straight white people, forever.</p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/verifying-pareto-1">Verifying Pareto</a></h1>
                    <p id='p1'>Testing Pareto's ideas with modern data.</p>
                    <br></br>
                </div>
            </div>
        </div>
    )*/
    return (
        <div className='Writings'>
            <div className='Writings-text'>
                <h1 id='Alpha'>ALPHABETICAL</h1>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/Dysgenics">Female Romantic Preferences are Dysgenic</a></h1>
                    <p id='p1'>What women want is not what society needs.</p>
                    <br></br>
                </div>
            </div>
        </div>
       )
}