import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updatePost, renderPost } from '../api'; // Assuming you're using axios for HTTP requests
import "../css/Michels.css";
import { useNavigate, useParams } from 'react-router-dom';
import { getStaticPageByName, postStaticApi } from '../api';

const StaticEditor = () => {
  const { page_name } = useParams();
  const quillRef = useRef(null);
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  let token = localStorage.getItem('authToken');



  useEffect(() => {
    const getContent = async () => {
        try {
            const response = await getStaticPageByName(page_name);
            console.log(response.data);
            setContent(response.data.content);
            console.log(response);
          } catch (error) {
            console.error('Error:', error);
          }

    };
    getContent();
  }, [page_name]);

  const handleSubmit = async () => {
    try {
      const payload = { token, content, page_name };
      let response;
      if (page_name) {
        response = await postStaticApi(payload);
      } 
      console.log(response.data);
      navigate('/');
    } catch (error) {
      console.error('Error submitting post:', error);
      alert("An error occurred: " + error.message);
      window.location.reload();
    }
  };

  const handlePaste = async (e) => {
    const clipboard = (e.clipboardData || window.clipboardData);
    const htmlContent = clipboard.getData("text/html");
    const wrapper = document.createElement('div');
    wrapper.innerHTML = htmlContent;
    const imgElements = Array.from(wrapper.querySelectorAll('img'));

    if (imgElements.length > 0) {
        e.preventDefault();  // Prevent Quill's default paste behavior.
        for (const imgElement of imgElements) {
            if (imgElement.src) {
                fetch(imgElement.src)
                    .then(response => response.blob())
                    .then(insertImageBlob)
                    .catch(error => {
                        console.error('Error fetching the image:', error);
                    });
            }
        }
    }
};

  const insertImageBlob = (blob) => {
      const reader = new FileReader();
      reader.onload = (event) => {
          const base64 = event.target.result;
          const quillInstance = quillRef.current.getEditor();
          const range = quillInstance.getSelection(true);
          quillInstance.insertEmbed(range.index, 'image', base64);
      };
      reader.readAsDataURL(blob);
  };

  return (
    <div className='Michels'>
      <div className='Michels-text'>
        <ReactQuill 
          value={content} 
          onChange={setContent}
          ref={quillRef}
          onPast={handlePaste} 
        />
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default StaticEditor;
