import "../css/Michels.css"


export default function Writings ()
{
    /*
    return (
        <div className='Writings'>
            <div className='Writings-text'>
                <h1 id='Alpha'>ALPHABETICAL</h1>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/OnPower">On Power by Bertrand de Jouvenel</a></h1>
                    <p id='p1'>A text published 1948 detailing the origin and structure of power.</p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/MichelsReview">Political Parties by Robert Michels</a></h1>
                    <p id='p1'>A text written in 1911 arguing for what its author calls the Iron Law of Oligarchy.</p>
                    <p id='p2'><a href="https://www.youtube.com/watch?v=DNypn1GzNwg">Youtube</a>&ensp;<a href="https://odysee.com/@HenryBearcroft:a/MichelsReview">Odysee</a></p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/Managerial">The Managerial Revolution by James Burnham</a></h1>
                    <p id='p1'>Burnham's synthesis of the Machiavellian Three, predicting the take-over of a manager elite and a centralized economy, published in 1941.</p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/MindAndSociety">The Mind and Society by Vilfredo Pareto</a></h1>
                    <p id='p1'>Pareto's magnum opus on epistemology, memetics, power, and the elite, published c. 1920.</p>
                    <br></br>
                </div>
                <div className='LinkAndSummary'>
                    <h1 id='myh1'><a href="/MoscaReview">The Ruling Class by Gaetano Mosca</a></h1>
                    <p id='p1'>Mosca's magnum opus, written c. 1895, detailing his various theories on society.</p>
                    <br></br>
                </div>
            </div>
        </div>
    )*/
    return (
        <p>Down for maintenance!</p>
       )
}