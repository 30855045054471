import "../../css/Michels.css"
import philosophy_of_hbd from "../../pdfs/philosophy_of_hbd.pdf";
var Latex = require("react-latex")

export default function PhilosophyOfHBD ()
{
    return (
        <div className='PhilosophyOfHBD'>
            <div className='PhilosophyOfHBD-text'>
                <h1 id='essayTitle'>The Philosophy of HBD and Why Our Model of Genetics Implies Hereditarianism</h1>
                <p>When I hear someone claim that the black-white IQ gap is significantly environmental, my brain melts. On a fundamental level, it’s just not possible. The direct molecular genetic evidence that is coming out these days isn’t needed to know this, and neither is the late 20th century psychometric and twin study evidence. The early 20th century progressives were correct; environmentalism and the blank slate fell with Lamarck, and hereditarianism rose with Darwin and Mendel.</p>
                <p>To see why this is, let’s steel-man the environmentalist position. The most intelligent argument I have heard generally denies that US blacks are oppressed. It says that, instead, roughly speaking, the problem is their “culture.” Maybe the IQ gap is 50% genetic, 50% environmental. Who knows?</p>
                <p>Darwin knows. And Mendel knows. I know. Here’s why: the black environment encourages high IQ as much as the white environment. To say otherwise is to say the black people are not rewarded for having a high IQ. The fact of the matter, however, is that black people are disproportionately rewarded for having a high IQ. Anyone who has dealt with affirmative action knows this. Murray and Herrnstein showed in The Bell Curve that when matched for IQ, blacks out earn whites.</p>
                <p>Imagine this. There’s a white underclass trapped in violence because of some great oppression. There is no genetic difference between them. If their kids don’t choose the gangsta life, they join the upper class and break free of the cycle. If the underclass breeds at minimum replacement rates, and half of their kids don’t choose the gangsta life, then every generation the underclass is cut in half. Now, if you make everyone brown, even those who exited, and measure their IQs, every generation the IQ gap will be cut in half. If the IQ gap does not shrink, it means that none of the kids are exiting, or those that are don’t get an IQ boost, because the gap isn’t environmental. Since white people exit the underclass at significant rates, if black people never chose to exit, that would be a genetic difference, i.e. their choice, and the IQ gap would be their fault and at least 100% genetic in broad terms (as opposed to narrow). Since blacks actually do exit the underclass at a rate above zero, we don’t need to worry about this. All we need to do is see how much the gap is shrinking. Because their environment promotes exiting, and because Lamarck was wrong and acquired characteristics are not passed down, the environmental portion of the gap should be automatically getting destroyed.1</p>
                <p>This means all hereditarianism need to do is establish that there is a gap and that it is not shrinking. All environmentalists need to do is establish that there is a gap and it is shrinking. Anyone who disagrees with this is a Lamarckian. This might not be the epic own it appears to be to those who have just read a high school biology text book. Many might proudly proclaim that the brain actually is a Lamarckian machine. Others believe in epigenetics, which is literal Lamarckism. Notice, reader, that anything beyond this point is on whether or not some degree of Lamarckism is true. Darwin and Mendel have implied HBD, given a static phenotypic difference in an environment which favors one end of that difference. We have to stray from them to rescue the environmentalist position, in lieu of either the belief that black people are secretly still oppressed in a world where 2/3 of everyone in TV commercials are black, half the musicians are black, and every employer and college is ready and willing to hire less qualified black applicants in the place of more qualified white ones, or evidence that the gap is closing.</p>
                <p>To conclude, I will briefly rebut Lamarckism, though this should not be necessary, since it was rebutted by evolutionists over 100 years ago. The mind is not a true Lamarckian machine, in lieu of epigenetics; acquired trait inheritance relies on the teaching of younger generations. Moral inclinations cannot be learned, of course, because these things are the stuff of thought itself, and not things which thought grasps (don’t confuse the inclinations themselves and descriptive knowledge of said inclinations). Descriptive consequences can be taught, along with other forms of descriptive knowledge, but what can be taught can be untaught. If a teaching (“meme”) is not adaptive, it will be selected against; we have already handled this case above, it would seem. Some might argue that at certain ages, certain actions permanently change the brain. The problem is that we have established that the black environment incentivizes high IQ. As long as some black parents are doing it right, as incentivized, there will be some level of exit from the underclass, and the gap should shrink accordingly. Regardless, the mind is clearly not that fragile, but that’s a separate topic.</p>
                <p>That leaves epigenetics. Geneticists have found that slavery won’t change your genes, but it may change your gene expression. If this were permanent, it would effectively cause a genetic difference. If it’s not permanent, then equal environmental incentives should lead to its reversal (if something super special needs to happen to reverse it, it’s effectively permanent in nature, meaning we are now talking about using nanobots or drugs to demethylate black IQ genes, which is ontologically equivalent to talking about using CRISPR to mutate black IQ genes).  Nothing further needs to be said, though the reader may like to know that in general, acquired epigenetic changes are wiped during gamete formation and fertilization, and it has been found that severe changes, such as in agouti mice, disappear in about 3 generations when a normal diet is restored.</p>
                <p>One last thing. Our model of genetics implies hereditarianism, broadly speaking. In other words, the black-white IQ gap is 100% genetic, since blacks and whites live in the same country and we make our environments. We have the same land and material around (under Lamarckism, having different land in the past made the giraffe, etc), the only question is whose genes cause the gap. Is it white oppressor genes or black low IQ genes? It’s one or the other, or a combination. Either white people are keeping black people from having good environments, or black people can’t create good environments. People are genetic machines. If they have the same opportunities given to them, all differences are genetic.</p>
                <Latex>{`$$\\frac{1}{9}$$`}</Latex>
                <div className='author'>
                    <p>Henry Bearcroft, March 27th, 2022</p>
                </div>
                <a href={philosophy_of_hbd}><p><i>Download PDF</i></p></a>
            </div>
        </div>
    )
}