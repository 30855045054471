import "../../css/Michels.css"
import mosca from "../../pdfs/mosca.pdf";
var Latex = require("react-latex")

export default function Mosca ()
{
    return (
        <div className='Mosca'>
            <div className='Mosca-text'>
            <h1 id='essayTitle'>Review of <i>The Ruling Class</i></h1>
                <h2>Introduction</h2>
                <p>Mosca's <i>The Ruling Class</i> is a broad, and somewhat lengthy, work of early 20th century political theory. In it, he demonstrates an impressive awareness of historical facts, and exposits on a wide variety of topics, including the structure of the ruling class, memetics, forms of government, civilization growth and decay, and the workings of history.  Though many good things could be said of the work, at many points it is quite repetitive, and evidential standards are quite 19th century. The whole book is essentially written as follows: Mosca states a general principle he believes to be true, gives one to three examples from history he believes to be confirmatory, discuss the consequences of this principle being true, and then skip onto the next principle. Chapters are structured to accommodate this format -- each chapter contains numerous subsections. The names can be found at the top of every other page, or in the table of contents, but in the text each section is simply numbered. Transition between sections within chapters is often jarring.</p>
                <p>In this review, I seek to give a brief account of Mosca's thought, alongside a rudimentary evaluation of the evidence he presents for his ideas, and the ideas themselves.</p>
                <h2>The Dynamics of History</h2>
                <p>The book begins with a discussion on the workings of history. By this I mean what was discussed in my essay <i>The Culture Equation.</i> In that essay, I put forth the following equation as a potential model for how history precedes:</p>
                <Latex>{`$$C = E[P] = E[G] + E[Env]$$`}</Latex>
                <p>That is, culture is the expected value of phenotypes of the ruling class which we know from behavioral genetics to be the sum of the expected values of genotypes and environments. Environment can include techno-epoch and memepool.</p>
                <p>I would describe this equation as being a statement of what one might call "Historical pluralism", as opposed to schools of thought like "Historical materialism", "Historical idealism," or "Historical racialism." Mosca, though he does not state it in clear, mathematical terms, subscribes to historical pluralism. The first chapter of the book is a refutation of historical racialism as well as a theory which was evidently popular in his day which claimed that history was determined by climate. Mosca's use of evidence here is simple: he cites the civilizations which have existed among non-Aryan races, and also the civilizations which have existed in different climates. He is particularly bothered by strains of Aryanism which state cold climates and Aryan races are superior to southern Europeans. Mosca, of course, is Sicilian, and was a representative in the Italian parliament in his day. Here is some of what he says on race. First, before addressing the superiority of different types of white people, Mosca passes judgment on certain non-white races:</p>
                <q id="quote1"><i>It seems to us an established fact that the most primitive races, those which anthropologists call ``lower'' the Fuegians, the Australians, the Bushmen, and so on are physically and intellectually inferior to the others. Whether that inferiority is innate, whether it has always existed, or whether it is to be attributed to the barrenness of their habitats, to the meagerness of the resources that their surroundings offer and to the abject poverty resulting, is a question that it is neither easy nor essential for us to answer. After all, these races represent only a very minute fraction of mankind, and that fraction is rapidly dwindling before the expansion of the white race, which is being followed in its turn, in many places, by an infiltration from the yellow race. In strict justice we are obliged to recognize that the prosperity of the white and yellow races in localities where the aborigines barely managed to subsist has not been wholly due to the organic superiority which the former boastfully claim. The newcomers bring with them knowledge and material means which enable them to reap an ample livelihood from soils that of themselves would yield practically nothing. The Australian native for centuries upon centuries was content to track the kangaroo, bring down birds with his boomerang or, if worse came to worst, eat a lizard. But we must remember that he had no means of securing the seeds to grow grains or other edible plants, or the breeders for flocks of sheep, which the English colonists had at their disposal.</i></q>
                <p>Not a thing in this book is quantified. Instead, it consists entirely of Mosca's qualitative judgments, based off of small glimpses of uncited factoids. It is implied that the aborigines are probably inferior due to their total lack of civilization. No quantifiers of this judgment are given. The question of nature vs. nurture is imprecisely addressed with reference to a vague notion that Europeans and Asians have expanded very rapidly in aboriginal lands, but that this might have been helped by their possession of farming knowledge and equipment. Things are left to be quite subjective and qualitative. The whole work is like this; as such, it is decisively sub-scientific. Another case in point, Mosca writes on the phenotype of American blacks:</p>
                <q id="quote1"><i>Are Indians and Negroes on the whole inferior to whites as individuals? While most people would answer with a ready and emphatic yes, some few with equal promptness and resolve say no. As for us, we find it as hard to agree as to disagree in terms at all positive. Observers rarely fail to report, in strictly primitive groups of these races, individuals who are outstanding for qualities, now of mind, now of heart. Where the American aborigines have mingled with the whites and adopted their civilization, they have not failed to produce distinguished men in nearly all branches of human activity, and under identical conditions the Negroes can boast of a list of names almost as long. Nevertheless, one has to admit, as regards both these races, that the roster of conspicuous individuals is very brief as compared with the number of individuals who have been, and are, in a position to enjoy the advantages offered by civilized life. Some weight, however, has to be given to a remark that was made to Henry George by a scholarly Negro bishop, that Negro school children do as well as white children and show themselves just as wide-awake and intelligent up to the age of ten or twelve; but as soon as they begin to realize that they belong to a race that is adjudged inferior, and that they can look forward to no better lot than that of cooks and porters, they lose interest in studying and lapse into apathy. In a great part of America colored people are generally regarded as inferior creatures, who must inevitably be relegated to the lowest social strata. Now if the disinherited classes among the whites bore on their faces the indelible stamp of social inferiority, it is certain that few individuals indeed among them would have the energy to raise themselves to a social position very much higher than the one to which they were born.</i></q>
                <p>Nothing on probability distributions, just some allusion to some people subjectively reporting that there was a smart black guy on occasion. To top it off he concludes that blacks are put down because a Negro bishop said so! This passage is prescient because it is easy for one with a vast knowledge of contemporary HBD to contrast this sort of sub-scientific word thinking with the sort of quantified rigor we now posses. The difference between my project on power and Mosca's thoughts on political theory are precisely the same as the difference between this passage and my essay on the black-white IQ gap, or between the passage and HBD in general. The reason why I finished this book is that I am forgiving of this type of thing if it occurred sufficiently long ago, and Mosca makes up for it somewhat with his apparently broad view of history, from which he may be able to derive some of the accuracy he lacks due to the lack of quantified reasoning.</p>
                <p>As a last comment on Mosca's racial views, his discussion of Aryanism is simply as follows:</p>
                <q id="quote1"><i>If some doubt may be raised as to the aptitude of Negroes and American Indians for the higher forms of civilization and political organization, all perplexity vanishes as regards the Aryans and the Semites, the Mongolian, or yellow, race and that dark Asiatic race which lives mixed with the Aryan stock in India and has fused with the yellow in southern China, in Indo-China and perhaps in Japan. These races taken together make up more than three-fourths, and perhaps as much as four-fifths, of all mankind. We say nothing of the Polynesian race. It may well have superior capacities, but being scant in numbers and dispersed over small islands, it has not been able to create any great civilization.The Chinese succeeded in founding a highly original civilization which has shown wondrous powers of survival and even more wondrous powers of expansion. Offshoots in large part of Chinese civilization are the cultures of Japan and Indo-China, and the Sumerian people which founded the earliest civilization in Babylonia seems to have belonged to a Turanian stock. The dark Asiatic race seems to have developed a very ancient civilization in Elam, or Susiana, and an autochthonous culture apparently existed in India before the coming of the Aryans. Egypt owes her civilization to a so-called sub-Semitic or Berber race, and Nineveh, Sidon, Jerusalem, Damascus and perhaps even Sardis belonged to the Semites. Reference to the more recent civilization of the Mohammedan Arabs seems to us superfluous.</i></q>
                <p>The superiority of Aryans is dealt with in only two paragraphs, and the reasoning goes: other races had civilizations, so Aryans are not superior. While I am not an Aryanist, I am doubtful that the Aryanists of Mosca's time were unaware of the existence of Chinese, or Egyption, or Babylonian, or Roman civilization. Mosca just sort of crafts his own frame and scarcely deals with much outside of it. Only when the ideas he attacks are really profoundly stupid do his pronouncements become totally adequate. For instance, toward the end of the book Mosca deals with Historical materialism, the Marxist view he summarizes as the idea that change in economic organization is necessary and sufficient for change in social organization. Mosca goes on for a few pages, citing multiple instances like the rise of Christianity and the fall of the Roman empire, wherein social organization changed without change in economic organization. He also claims that economic organization changed in the 19th century with little to no change in social organization. He claims briefly that, per his reading, the democratic revolutions occurred before changes in economic structure. Allegedly, the economy was greatly restructed throughout the 19th century, and political organization stayed representative. We are not given independent evidence of this fact, other than that Mosca believes in it, in his vast accumulation of knowledge. The way in which the economy changed after the revolutions is described by Mosca without reference, lending to his credibility, but again there are no quantifiers. No measurements. Mosca simply waves his hands and says he has seen and read that new machines exist, that this or that came into existence in this year. Rigorous work was not done to measure the effect of some machine on some metric which is hypothesized to change per some hypothesis.</p>
                <p>Epistemology aside, Mosca explicitly rejects a one-track view of history, stating that any such view is destined to be flawed. Instead, throughout the book, he puts emphasis on technological change, memetics, and ruling class virtue. His thoughts on the former are that military technology is highly related to government organization. We are given typical levels of evidence for this per Mosca: he claims, qualitatively, for instance, that absolutism trumped feudalism as the firearm enabled it to, and then absolutism gave way to representative government when the new economic masters realized the power of the firearm against the old aristocratic abolutists. He also mentions the association between the Greek hoplite and their representative government, and then the transition to the Roman equestrian. As usual, nothing here is particularly definitive, but it is an interesting hypothesis.</p>
                <p>Mosca's thoughts on ruling class virtue are even more vague, but they overlap with my hypotheses. He says that the level of civilization a culture achieves is dependent on the intellectual and moral fortitude of its middle class, which he considers to be the base of a ruling class, a "secondary ruling class." If corruption sets in for whatever reason, that can kill a civilization. The reasons why corruption might set in remain obscure in this work; Mosca seems to have a notion of a cyclical rise and fall of civilizations, and explicitly hopes that perhaps political science could, in the future, prevent the fall of civilizations, which he considers to be great catastrophes which are potentially preventable.</p>
                <h2>Memetics</h2>
                <p>Mosca discusses what might be today called "memetics" at length. He believes it is evident that model two from my essay <i>Three Models of Memetics & The Lindsay Fallacy</i> is a good descriptor of reality; specifically, he says that memes essentially appeal to pre-existing impulse, and these impulses in men may be good or bad. He is sympathetic to Christianity, and averse to socialism, saying the former acts upon the altruistic instincts of man, while the latter primarily feeds upon hate and resentment. All ideologies, he says, are mixed, and must appeal to both good or bad instincts, or else just bad. Mosca doubts that an ideology can ever be successful if it only appeals to the good, because like me, he frames the good as altruism, and doubts the ability of men to totally deny themselves. But Christianity, while it says woe to the rich, is ultimately based upon love, and promises revenge in the next life, while socialism demands the rich to be punished now. For Mosca, this difference makes Christianity a positive force for humanity (though he does not seem to be a believer), while socialism is regarded as a pest.</p>
                <p>Mosca decries the materialism left in the wake of the lapse of the faith of the ruling class, saying it has encouraged the adoption of dangerous, socialist beliefs, and depressive ideologies which claim that man is a mere collection of chemicals. In one interesting passage, he states that he believes that the decline of religion is most to blame for low fertility rates, that when religion was decaying in the ancient world, before the introduction of Christianity, abortion and adultery and infant exposure shot up in frequency.</p>
                <p>Mosca takes the ruling class to be genuine believers of their political formulas. "Political formula" is a term coined by Mosca, though not widely used throughout his text, that refers to the founding myth of a ruling class. Mosca claims that, based on his extensive study of history, men are far more disposed to show obedience to an idea than to another man. Thus kings throughout history have relied on divine right, and new governments rest upon the claim of popular sovereignty. He claims that Rome was also based on popular sovereignty: "The complicated hierarchy of civil and military functionaries in the Roman Empire rested upon the will of the emperor, who, at least down to Diocletian's time, was assumed by a legal fiction to have received from the people a mandate to rule the commonwealth." Specifically, Mosca defines political formula thusly: "This legal and moral basis, or principle, on which the power of tie political class rests, is what we have elsewhere called, and shall continue here to call, the 'political formula.' (Writers on the philosophy of law generally call it the 'principle of sovereignty')."</p>
                <p>He claims that the rise of socialism and universal suffrage is associated with the genuine guilt the ruling class felt by basing its moral legitimacy on equality. Due to the genuine belief the ruling class have, on average, in their political formula, their formula can doom them to destruction. They cannot, according to Mosca, simply seek their material self interest. They remain attached, throughout history, to their formula, even to their doom. This explains, according to Mosca, why ruling classes often become sclerotic, and fail to change with the times. I quite doubt this interpretation of history, and I would like to see a more scientific approach to this question taken. Mosca's evidence is, of course, scant, and subjective or qualitative. His observations could be explained with the idea that ruling classes become sclerotic when they are no longer fit to rule; their material interests prevent them from simply giving up power. This would be more parsimonious with the observation that at any time since 1800 the ruling class, based upon the idea of equality, has obviously, and unashamedly failed to extend equality to everyone, even in legal terms. In the spirit of Mosca's epistemic standards, it suffices to allude to the condition of the average 17 year old in contemporary society.</p>
                <h2>Forms of Government and Juridicial Defense</h2>
                <p>More frequent than any other coinage in this book is the term and idea of "juridicial defense." This is essentially Mosca's term for seperation of powers. Mosca believes that a variety of interests must exist in the ruling class, lest it become single-sighted and overbearing. This is relatively standard structural liberalism.</p>
                <p>The evidence presented is lacking as always. Much time is spent discussing the supremacy of the 19th and 20th century relative to others. Mosca claims that only with juridicial defense can the necessary level of intellectual freedom be achieved to make scientific discovery. I am not averse to this conclusion but Mosca, even with qualitative data, could have put more effort into exploring the dynamics of different governments relative to freedoms granted. It is very much an axiom of Mosca's, not rigorously supported, that a social force will eat like a fat dog -- that is, if it is able it will take everything for itself at the expense of the rest of society. It is on these grounds that he builds his account of juridicial defense, that he interprets vague historical anecdata without rigorous investagation of the structure of the ruling classes at different times, and upon these grounds he rejects fascism, Marxism, and syndicalism, saying that the ascendency of a Communist party, or a military dictator, or a trade union would necessarily mean the subordination of what he sees as plural valid interests to the one. He heavily cites past thinkers, like Aristotle and Montesquieu, whom he says support mixed forms of government.</p>
                <p>Likewise, near the end of the text the terms "aristocratic and democratic tendency" and "autocratic or liberal impulse" are presented. These refer, respectively, to the tendency of a ruling class to repopulate itself with its own descendants, or with new blood, and whether or not a new ruling class is selected from above or below. Mosca claims that these tendencies should be found in multiple institutions throughout society, and should be balanced.</p>
                <p>I would like to see these ideas better investigated rigorously and quantitatively. Ideally, a way to measure the levels of juridicial defense and freedoms of a society would be developed, and correlations could be calculated. The same goes for the tendencies Mosca defines.</p>
                <h2>Conclusion</h2>
                <p><i>The Ruling Class</i> is an interesting text with average rigor given its time period. I would not bother with such a text were it written today, since it was written over 100 years ago, this book serves as a interesting window to the past and as an interesting source of hypotheses. It is also somewhat canonical in elitist political theory and therefore serves to help familiarize the reader with the development of field, whatever that field's faults may be.</p>
                <h2>Addendum: Ruling Class and Social Type</h2>
                <p>I noticed that I failed to mention Mosca's treatment of two concepts: ruling class and social type. One of these is ironically the title of his book! The reason why I did not mention these concepts is that Mosca's treatment of these topics is inadequate. He spends maybe five, at most ten pages of his 450 page book discussing the ruling class. Much more space is devoted to juridicial defense, memetics, and so on.</p>
                <p>Mosca gives essentially no evidence for his claim that "everywhere is found an organized minority ruling over an unorganized majority", to paraphrase. I find his assumption reasonable (see my conclusion to my review of <i>Political Parties</i> -- disorganization could very well imply weakness while organization means strength) but his treatment of the topic quite lacking.</p>
                <p>One thing he does emphasize is that any king or small inner oligarchy invariably needs a supporting outer oligarchy. At some point he distinguishes these as the two levels of the ruling class. The former are kings or billionaires and the latter are managers or civil servants. He asserts with little support that the are generally aligned on their political formulas, and vaguely share class interests due to both essentially existing on behalf of the State.</p>
                <p>Social type has to do with the different possible types of ruling classes. Diversity of social type is important for juridicial defense, according to Mosca. Interestingly, he almost totally reduces this concept to ethnicity, and supports himself by referencing examples of ethnic intolerance throughout history.</p>
                <p>I was hoping for more hypothesis fodder relating to class interests and different types within an ethnicity. Sadly this was not given. Overall I found his treatment of these topics to be lacking.</p>
                <div className='author'>
                        <p>Henry Bearcroft, April 3, 2022</p>
                </div>
            <a href={mosca}><p><i>Download PDF</i></p></a>
            </div>
        </div>
    )
}