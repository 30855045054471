import "../css/Michels.css"

export default function Contact ()
{
    return (
        <div className='Michels'>
        <div className='Michels-text'>
            <p>Twitter: <a href="https://twitter.com/BronskiJoseph">@BronskiJoseph</a></p>
            <p>Secret Discord: <a href="https://discord.gg/narMU4yRJ7">Link</a></p>
            <p>Telegram: @josephbronski</p>
        </div>
        </div>
    )
}